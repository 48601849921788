import {
  compose,
  withFormik,
  withHooks,
  withTranslation,
  withStores,
} from "enhancers";
import { PageContent } from "layouts";
import {
  gql,
  paths,
  getErrorMessage,
  notifyError,
  homePath,
} from "utils/helper";
import {
  Box,
  Form,
  Field,
  TextField,
  Button,
  Notification,
  FieldArray,
  Typography,
  Grid,
  LogoUpload,
  Modal,
  Cron,
} from "components";

import { makeStyles } from "@material-ui/core";

import "react-js-cron/dist/styles.css";
import { AppColor } from "theme/app-color";
import { isEmpty, isEqual, omit } from "lodash";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: AppColor["Other/Info"],
    borderColor: AppColor["Other/Info"],
    color: "white",
    "&:hover": {
      backgroundColor: "#2296F3",
      borderColor: "#2296F3",
      color: "white",
    },
  },
}));

const Settings = (props: any) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Form>
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="h4">{props.t(".title")}</Typography>
        <Box mt={2}>
          <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
            {props.t(".titleDetail")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="row" mt={6}>
          <Box mr={2}>
            <Typography variant="h6">{props.t(".logo")}</Typography>
          </Box>
          <Typography variant="caption">{props.t(".logoDetail")}</Typography>
        </Box>
        <Box mt={6}>
          <FieldArray
            component={LogoUpload}
            name="images"
            hasEditPermission={props.hasEditPermission}
            noStartIcon
          />
        </Box>
        <Box mt={2}>
          <Field
            component={TextField}
            name="companyNameTh"
            type="text"
            label={props.t(".nameTh")}
            mt={4}
            fullWidth
            disabled={!props.hasEditPermission}
            fast={false}
            required
          />
          <Field
            component={TextField}
            name="companyNameEn"
            type="text"
            label={props.t(".nameEn")}
            mt={4}
            fullWidth
            disabled={!props.hasEditPermission}
            fast={false}
            required
          />
        </Box>
        <Box mt={2}>
          <Field
            component={TextField}
            name="companyDescriptionTh"
            type="text"
            label={props.t(".descriptionTh")}
            mt={4}
            multiline
            rows={9}
            fullWidth
            disabled={!props.hasEditPermission}
            fast={false}
            required
          />
          <Field
            component={TextField}
            name="companyDescriptionEn"
            type="text"
            label={props.t(".descriptionEn")}
            mt={4}
            multiline
            rows={9}
            fullWidth
            disabled={!props.hasEditPermission}
            fast={false}
            required
          />
        </Box>
        <Grid container spacing={6} mt="0px">
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField.Email}
              name="companyEmail"
              type="text"
              label={props.t(".email")}
              mt={4}
              fullWidth
              disabled={!props.hasEditPermission}
              fast={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField.PhoneNumber}
              name="companyPhoneNumber"
              type="phoneNumber"
              label={props.t(".phoneNumber")}
              mt={4}
              fullWidth
              disabled={!props.hasEditPermission}
              required
            />
          </Grid>
        </Grid>
        <Box mt={10}>
          <Typography variant="h4">{props.t(".syncSetting")}</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
            {props.t(".syncSettingDetail")}
          </Typography>
        </Box>
        {/* <Grid container spacing={6} mt="0px">
          <Grid item xs={12} sm={9}>
            <Field
              component={TextField}
              name="host"
              type="text"
              label="Host URL"
              mt={4}
              fullWidth
              disabled={!props.hasEditPermission}
              fast={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              component={TextField}
              name="port"
              type="text"
              label="Port"
              mt={4}
              fullWidth
              disabled={!props.hasEditPermission}
              fast={false}
              required
            />
          </Grid>
        </Grid> */}
        {/* <Box mt={2}>
          <Field
            component={TextField}
            name="path"
            type="text"
            label="Path"
            mt={4}
            fullWidth
            disabled={!props.hasEditPermission}
            fast={false}
            required
          />
        </Box> */}
        {/* <Grid container spacing={6} mt="0px">
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              name="username"
              type="text"
              label="Username"
              mt={4}
              fullWidth
              disabled={!props.hasEditPermission}
              fast={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              name="password"
              type="password"
              label="Password"
              mt={4}
              fullWidth
              disabled={!props.hasEditPermission}
              fast={false}
              required
            />
          </Grid>
        </Grid> */}
        <Grid container spacing={6} mt="12px" alignItems="center">
          <Grid item xs={12} sm={3}>
            <Typography variant="body1" color={AppColor["Text/Secondary"]}>
              {props.t(".syncEmData")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Field
              component={Cron}
              name="syncEmployeeScheduleTimeInCronFormat"
              type="text"
              mt={4}
              fullWidth
              disabled={!props.hasEditPermission}
              clearButtonProps={{ type: "default", danger: false }}
            />
          </Grid>
        </Grid>
        <Box mt={10}>
          <Typography variant="h4">{props.t(".reportSetting")}</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body1" color={AppColor["Text/Secondary"]}>
            {props.t(".reportSettingDetail")}
          </Typography>
        </Box>
        <Grid container spacing={6} mt="12px" alignItems="center">
          <Grid item xs={12} sm={3}>
            <Typography variant="body1">{props.t(".createReport")}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Field
              component={Cron}
              name="generateReportScheduleTimeInCronFormat"
              type="text"
              mt={4}
              fullWidth
              disabled={!props.hasEditPermission}
              clearButtonProps={{ type: "default", danger: false }}
            />
          </Grid>
        </Grid>
      </Box>
      {props.hasEditPermission && (
        <>
          <Button
            width={74}
            mt={10}
            mr={4}
            onClick={props.handleClickCancel}
            disabled={!props.dirty}
            variant="outlined"
          >
            {props.t(".cancel")}
          </Button>
          <Button
            type="submit"
            width={74}
            mt={10}
            disabled={!props.dirty}
            style={{ border: "none" }}
            variant="contained"
          >
            {props.t(".save")}
          </Button>
        </>
      )}
    </Form>
  </PageContent>
);

export const API = {
  FETCH_SETTING: gql`
    query FETCH_SETTING {
      setting {
        id
        companyNameTh
        companyNameEn
        companyDescriptionTh
        companyDescriptionEn
        companyEmail
        companyPhoneNumber
        syncEmployeeScheduleTimeInCronFormat
        generateReportScheduleTimeInCronFormat
        images {
          url
          fileName
        }
      }
    }
  `,
  UPDATE_SETTING: gql`
    mutation UPDATE_SETTING(
      $id: String
      $companyNameTh: String
      $companyNameEn: String
      $companyDescriptionTh: String
      $companyDescriptionEn: String
      $companyEmail: String
      $companyPhoneNumber: String
      $syncEmployeeScheduleTimeInCronFormat: String
      $generateReportScheduleTimeInCronFormat: String
      $images: [Upload!]
    ) {
      updateSetting(
        input: {
          id: $id
          companyNameTh: $companyNameTh
          companyNameEn: $companyNameEn
          companyDescriptionTh: $companyDescriptionTh
          companyDescriptionEn: $companyDescriptionEn
          companyEmail: $companyEmail
          companyPhoneNumber: $companyPhoneNumber
          images: $images
          syncEmployeeScheduleTimeInCronFormat: $syncEmployeeScheduleTimeInCronFormat
          generateReportScheduleTimeInCronFormat: $generateReportScheduleTimeInCronFormat
        }
      ) {
        id
        companyName
        companyDescription
        companyEmail
        companyPhoneNumber
        images {
          url
          fileName
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withPreventLeaveDirtyForm(),
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.settings.index" }),
  withHooks((props: any, hooks: any) => {
    const {
      useQuery,
      useMemo,
      useMutation,
      useEffect,
      useHandleSubmit,
      useCallback,
    } = hooks;
    const {
      currentUser,
      initialValues,
      values,
      setInitialValues,
      setValues,
      dirty,
      t,
    } = props;
    const classes = useStyles();
    const { loading, data, error, refetch } = useQuery(API.FETCH_SETTING, {
      fetchPolicy: "network-only",
    });
    const [updateSetting] = useMutation(API.UPDATE_SETTING);

    const hasEditPermission = ["SETTING_MANAGEMENT_EDIT"].every((perm) =>
      currentUser?.role?.permissions.includes(perm)
    );

    const title = t(".settingMenu");
    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        { path: null, label: title },
      ];
    }, [title, t]);
    const convertImagesToFilesObject = useCallback(async (images: any[]) => {
      const result = await Promise.all(
        images.map(async (img: any) => {
          if (img.fileName && img.url) {
            const blob = await fetch(img.url).then((r) => r.blob());
            const file = new File([blob], img.fileName, {
              type: blob.type,
            });
            return file;
          } else {
            return img;
          }
        })
      );
      return result;
    }, []);
    const setting = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.adminsPath().push();
        return null;
      }
      const { setting } = data;

      return setting;
    }, [loading, data, error]);

    const handleClickCancel = useCallback(() => {
      if (dirty) {
        Modal.open({
          title: t(".cancelEdit"),
          children: (
            <Box flex={1} mb={5} display="flex" flexDirection="row">
              <Typography variant="body1" color="#767676">
                {t(".cancelConfirm")}
              </Typography>
            </Box>
          ),
          cancelButtonLabel: t(".close"),
          okButtonLabel: t(".canceledEdit"),
          onOk: async ({ close }: any) => {
            close();
            setValues(initialValues);
          },
        });
      }
    }, [t, dirty, setValues, initialValues]);

    useHandleSubmit(
      async (values: any) => {
        try {
          await updateSetting({
            variables: values,
          });
          await refetch();
          setInitialValues(null);
          Notification.success(t(".saveSuccess"));
        } catch (e) {}
      },
      [updateSetting]
    );

    const setInitialAsync = useCallback(async () => {
      if (isEmpty(initialValues) && setting) {
        const images = await convertImagesToFilesObject(setting?.images);
        setInitialValues({ ...setting, images });
      }
    }, [initialValues, setInitialValues, setting, convertImagesToFilesObject]);

    useEffect(() => {
      setInitialAsync();
    }, [setInitialAsync]);

    return {
      title,
      breadcrumbs,
      classes,
      hasEditPermission,
      handleClickCancel,
      dirty,
    };
  })
);

export default enhancer(Settings);
