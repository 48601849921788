import { makeStyles } from "@material-ui/core/styles";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { useCallback } from "react";
import insuranceStore from "stores/insuranceStore";
import { Yup, gql, homePath, notifySuccess, paths } from "utils/helper";
import { InsurancePackagesNewPage } from "./new";
import { insuranceStepPath } from "../insuranceRoutes";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { history } from "components/common/BrowserRouter";
import { PackagePricingType } from "constants/enums/package-pricing-type";
import { isEmpty } from "lodash";
import { Modal } from "components";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    backgroundColor: "#376FD0",
    borderColor: "#376FD0",
    color: "white",
    "&:hover": {
      backgroundColor: "#376FD0",
      borderColor: "#376FD0",
      color: "white",
    },
  },
  secondaryButton: {
    backgroundColor: "white",
    borderColor: "#376FD0",
    color: "#376FD0",
    "&:hover": {
      backgroundColor: "white",
      borderColor: "#376FD0",
      color: "#376FD0",
    },
  },
}));

export const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    packages: stores.insuranceStore.packages,
  })),
  withFormik({
    validationSchema: Yup.object().shape({
      nameTh: Yup.string().required("ต้องไม่เว้นว่างเอาไว้"),
      nameEn: Yup.string().required("ต้องไม่เว้นว่างเอาไว้"),
    }),
  }),
  withPreventLeaveDirtyForm({ onOk: () => history.goBack() }),
  withTranslation({ prefix: "pages.main.insurance.InsurancePackage" }),
  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useEffect,
      useParams,
      useState,
      useQuery,
      useMemo,
    } = hooks;
    const { packages, setPropsToFormikBag, t, setInitialValues } = props;
    const classes = useStyles();
    const { id, insuranceId } = useParams();
    const [title, setTitle] = useState();

    const { data: types, loading: insuranceTypeLoading } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );

    const { data: detail, loading: detailLoading } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id: insuranceId },
        onCompleted: (data: any) => {
          const response = data.masterInsuranceDetail;
          if (!insuranceTypeLoading)
            insuranceStore.initialMasterInsurance(response);
        },
        fetchPolicy: "network-only",
      }
    );

    const packagePricingOptions = useMemo(() => {
      const options = Object.keys(PackagePricingType).map((key) => {
        const packagePricingType =
          PackagePricingType[key as keyof typeof PackagePricingType];

        const label = t(`.${packagePricingType}`);
        const value = key;
        return { label, value };
      });
      return options;
    }, [t]);

    const insuranceType = useMemo(() => types?.insuranceTypes || [], [types]);

    const onBack = useCallback(
      () => insuranceStepPath.insurancePackage(insuranceId).push(),
      [insuranceId]
    );

    useHandleSubmit(
      async (values: any) => {
        const clearEmptyInsurances = values.insurances.filter(
          (value: any) => !isEmpty(value)
        );

        if (clearEmptyInsurances.length > 0) {
          insuranceStore.updateInsurancePackageTemp(id, values);
          onBack();
          notifySuccess(t(".success"));
        } else {
          Modal.alert({
            title: "ไม่สามารถบันทึกได้",
            children: "กรุณาเลือกแบบประกันอย่างน้อย 1 ประเภท",
          });
        }
      },
      [packages, id]
    );

    useEffect(() => {
      setTitle(packages[id]?.nameTh);
      setInitialValues(packages[id]);
      setPropsToFormikBag(packages[id]);
    }, [setInitialValues, setPropsToFormikBag, packages, id, insuranceId]);

    const isLoading = useMemo(() => {
      return detailLoading || insuranceTypeLoading;
    }, [detailLoading, insuranceTypeLoading]);

    const config = useMemo(() => {
      if (isLoading) return;
      const year = detail?.masterInsuranceDetail.year;
      return {
        year,
      };
    }, [isLoading, detail]);

    const breadcrumbs = useMemo(() => {
      return [
        {
          path: homePath(),
          label: t(".breadcrumbs.home"),
        },
        {
          path: paths.insurancePath(),
          label: t(".breadcrumbs.insurance"),
        },
        {
          path: insuranceStepPath.insurancePackage(insuranceId).toString(),
          label: t(".breadcrumbs.insuranceDetail", {
            year: config?.year,
          }),
        },
        { path: null, label: title },
      ];
    }, [t, insuranceId, config, title]);

    return {
      classes,
      onBack,
      title,
      breadcrumbs,
      viewInsuranceState: "editing",
      packageIndex: id,
      packagePricingOptions,
      insuranceType,
    };
  })
);

export default enhancer(InsurancePackagesNewPage);
