import { compose, withHooks, withTranslation } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Modal, ExcelGenerator } from "components";
import { gql, notifySuccess, paths, formatDate, homePath } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { isEmpty } from "lodash";
import { BreadcrumbsProps } from "layouts/PageContent";
import { ROLES } from "constants/enums/role";
import { GridColDef } from "@material-ui/data-grid";

const AdminIndexPage = (props: any) => (
  <PageContent
    title={props.t(".title")}
    breadcrumbs={props.breadcrumbs}
    pageActions={[
      {
        children: props.t(".addButton"),
        startIcon: <AddIcon />,
        onClick: () => paths.adminNewPath().push(),
        color: "primary",
        permittedRoles: ["ADMIN_MANAGEMENT_EDIT"],
      },
    ]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        {props.t(".admins")}
      </Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.adminEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ADMINS: gql`
    query FETCH_ADMINS {
      admins {
        code
        createdAt
        firstName
        lastName
        id
        updatedAt
        role
        backofficeUser {
          email
          availableStatus
        }
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({
    prefix: "pages.main.admins.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { t } = props;
    const {
      useState,
      useMemo,
      useCallback,
      useQuery,
      useMutation,
      useEffect,
    } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_ADMINS, {
      fetchPolicy: "network-only",
    });

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.admins.map((admin: any) => {
        const {
          firstName,
          lastName,
          isSuperAdmin,
          backofficeUser,
          role,
          ...rest
        } = admin;

        const { availableStatus, email } = backofficeUser;

        return {
          ...admin,
          email,
          role: role,
          availableStatus: availableStatus
            ? t(".columns.values.availableStatus.active")
            : t(".columns.values.availableStatus.inactive"),
          fullName:
            (isEmpty(firstName) || firstName == null) &&
            (isEmpty(lastName) || lastName == null)
              ? null
              : `${firstName || ""} ${lastName || ""}`,
        };
      });
    }, [loading, error, data, t]);

    const downloadProducts = useCallback(async () => {
      ExcelGenerator.generate({
        fileName: `admins-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
        columns: [
          {
            title: "รหัส",
            field: "code",
          },
          {
            title: "ชื่อ นามสกุล",
            field: "fullName",
          },
          {
            title: "เบอร์โทรศัพท์",
            field: "phoneNumber",
          },
          {
            title: "อีเมล",
            field: "email",
          },
          {
            title: "ไลน์",
            field: "line",
          },
        ],
        data: data.admins,
      });
    }, [data]);

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    const columns = useMemo(
      (): GridColDef[] => [
        { width: 100, field: "code", headerName: t(".columns.code") },
        {
          width: 240,
          field: "fullName",
          headerName: t(".columns.fullName"),
        },
        {
          width: 140,
          field: "role",
          headerName: t(".columns.role"),
        },
        {
          width: 240,
          field: "email",
          headerName: t(".columns.email"),
        },
        {
          width: 160,
          field: "updatedAt",
          headerName: t(".columns.updatedAt"),
          type: "date",
        },
        {
          field: "availableStatus",
          headerName: t(".columns.status"),
          type: "tag",
          align: "center",
          headerAlign: "center",
        },
        // {
        //   width: 160,
        //   field: "lastActiveAt",
        //   headerName: t(".columns.lastActiveAt"),
        //   hide: true,
        //   type: "date",
        // },
        // {
        //   width: 160,
        //   field: "createdAt",
        //   headerName: t(".columns.createdAt"),
        //   hide: true,
        //   type: "date",
        // },
        // {
        //   width: 40,
        //   field: "actions",
        //   headerName: " ",
        //   filterable: false,
        //   sortable: false,
        //   type: "actions",
        // },
      ],
      [t]
    );

    return {
      breadcrumbs,
      tableData,
      loading,
      downloadProducts,
      columns,
    };
  })
);

export default enhancer(AdminIndexPage);
