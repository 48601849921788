import {
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
} from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Field,
  Form,
  Paper,
  Table,
  TextField,
  Typography,
} from "components";
import Authorize from "components/Authorize";
import Loader from "components/Loader";
import { MaterialIcon } from "components/common/MaterialIcon";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";
import { PERMISSIONS } from "constants/enums/permissions";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { get, groupBy, isEmpty, isEqual, uniq } from "lodash";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import {
  formatDate,
  gql,
  publishedAlert,
  notifyError,
  notifySuccess,
} from "utils/helper";
import { handleNavigateEnrollmentStep } from "./enrollmentRoutes";

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    margin: 1vw;
    min-width: 280px;
    overflow: hidden;
  }
`;

const StyledTable = styled(Table)(({}) => ({
  "& .highlight": {
    backgroundColor: "#E0E0E0",
  },
}));

const EnrollmentAttendeeComponent = (props: any) => (
  <>
    <Paper px={4} py={6}>
      <Form>
        <Typography variant="h4" color={AppColor["Text/Black"]}>
          {props.t(".title")}
        </Typography>
        <Box display="flex" mt="12px" alignItems="center">
          <Box style={{ width: "100%", marginRight: "24px" }}>
            <Field
              component={TextField}
              name="codeAndName"
              type="text"
              label={props.t(".field")}
              fullWidth
              fast={false}
              unit={<MaterialIcon name="Search" style={{ fontSize: "24px" }} />}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button
              mr="16px"
              style={{ border: "none", width: "64px" }}
              onClick={props.handleResetFilter}
              variant="type"
            >
              {props.t(".reset")}
            </Button>
            <Button
              color="Primary"
              variant="contained"
              onClick={props.handleFilter}
              style={{ width: "64px" }}
            >
              {props.t(".filter")}
            </Button>
          </Box>
        </Box>
        <Box mt="40px">
          {Object.entries(props.attendees).length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ height: "68px" }}
            >
              <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                {props.t(".noAttendee")}
              </Typography>
            </Box>
          ) : (
            <>
              {props.isFilterNotFound ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "68px" }}
                >
                  <Typography
                    variant="body1"
                    color={AppColor["Text/Dark Grey"]}
                  >
                    {props.t(".filterNotFound")}
                  </Typography>
                </Box>
              ) : (
                <>
                  {Object.entries(props.attendees)?.map((attendee: any) => (
                    <Accordion
                      expanded={[attendee[0]].every((attend: any) =>
                        props.expanded.includes(attend)
                      )}
                      key={attendee[0]}
                      onChange={props.handleChange(attendee[0])}
                      style={{
                        borderBottom: `1px solid ${AppColor["Background/Dark Grey"]}`,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <MaterialIcon
                            name="ExpandMore"
                            htmlColor={AppColor["Text/Dark Grey"]}
                          />
                        }
                      >
                        <Typography
                          variant="body1"
                          color={AppColor["Text/Black"]}
                        >
                          {attendee[0] + " (" + attendee[1].length + ")"}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <StyledTable
                          className="attendeeTable"
                          columns={props.columns}
                          rows={attendee[1]}
                          loading={props.loading}
                          density="compact"
                          autoHeight
                          disableSelectionOnClick
                          hideFooter
                          getRowClassName={(params: any) => {
                            const fil = props.filterArrays.filter(
                              (filterArray: any) =>
                                filterArray.id === params.row.id
                            ).length;
                            return fil > 0 ? "highlight" : "none";
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              )}
            </>
          )}
        </Box>
        <Box
          mt="40px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
              {!props.viewOnly && (
                <Button
                  color="Primary"
                  variant="contained"
                  onClick={props.handleNext}
                >
                  {props.t(".next")}
                </Button>
              )}
            </Authorize>
          </Box>
          <Box display="flex" alignItems="center" ml="8px">
            <Typography variant="caption" color={AppColor["Text/Black"]}>
              {props.t(".lastUpdate", {
                updatedAt: props.updatedAt,
              })}
            </Typography>
            <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
              {!props.viewOnly && (
                <Button
                  color="Primary"
                  variant="contained"
                  onClick={props.handleUpdateData}
                  ml="8px"
                  startIcon={
                    <MaterialIcon name="Sync" style={{ fontSize: "24px" }} />
                  }
                >
                  {props.t(".updateData")}
                </Button>
              )}
            </Authorize>
          </Box>
        </Box>
      </Form>
    </Paper>
    <Dialog open={props.isShow}>
      <DialogTitle></DialogTitle>
      <DialogContent
        style={{
          objectFit: "contain",
          padding: "59px 24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Loader />
        <Box mt="24px">
          <Typography>{props.t(".syncModal")}</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  </>
);

const API = {
  FETCH_EMPLOYEES: gql`
    query FETCH_EMPLOYEES($enrollmentId: String) {
      getEmployees(input: { enrollmentId: $enrollmentId }) {
        id
        code
        groupTh
        groupEn
        positionTh
        positionEn
        departmentTh
        departmentEn
        nameTh
        nameEn
        probationEndDate
      }
    }
  `,
  SYNC_EMPLOYEES: gql`
    mutation SYNC_EMPLOYEES($id: String!) {
      reSyncEmployees(id: $id) {
        id
      }
    }
  `,
  CREATE_ATTENDEE_GROUP: gql`
    mutation CREATE_ATTENDEE_GROUP($employees: [JSON!], $enrollmentId: String) {
      createAttendeeGroup(
        input: { employees: $employees, enrollmentId: $enrollmentId }
      ) {
        subState
      }
    }
  `,
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      enrollment(id: $id) {
        state
        subState
        startDate
        endDate
        existingAttendeeSelectionPeriodInDays
        incomingAttendeeSelectionPeriodInDays
        updatedAt
      }
    }
  `,
  GET_SYNC_TRANSACTION: gql`
    query GET_SYNC_TRANSACTION {
      getSyncTransaction {
        lastSyncedEmployeeAt
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    lastSyncedEmployeeAt: stores.enrollmentStore.lastSyncedEmployeeAt,
  })),
  withFormik({
    mapPropsToValues: () => ({ codeAndName: null }),
  }),
  withTranslation({ prefix: "pages.main.enrollment.enrollmentAttendee" }),
  withHooks((props: any, hooks: any) => {
    const {
      useQuery,
      useMemo,
      useCallback,
      useState,
      useMutation,
      useParams,
      useDataTranslation,
    } = hooks;
    const { values, setValues, t } = props;
    const { id } = useParams();
    const { loading, data, refetch } = useQuery(API.FETCH_EMPLOYEES, {
      variables: { enrollmentId: id },
      fetchPolicy: "network-only",
    });
    const { data: enrollmentQry } = useQuery(API.GET_ENROLLMENT, {
      variables: { id },
    });
    const [syncEmployee] = useMutation(API.SYNC_EMPLOYEES, {
      skipSetError: true,
      onCompleted: async () => {
        setIsShow(false);
        notifySuccess(t(".syncSuccess"));
        await refetch({ variables: { enrollmentId: id } });
        await refetchLastSyncEmployee();
      },
      onError: (errorResponse: any) => {
        setIsShow(false);
        const errors = get(errorResponse, "networkError.result.errors");
        if (errors[0]?.extensions.code === "SyncEmployee") {
          notifyError("Sync Employee Error");
        } else if (errors[0].message === "updateUnsuccessfully") {
          publishedAlert();
        }
      },
    });
    const [createAttendeeGroup] = useMutation(API.CREATE_ATTENDEE_GROUP, {
      onCompleted: (data: any) => {
        handleNavigateEnrollmentStep(id, data?.createAttendeeGroup?.subState);
      },
      skipSetError: true,
      onError: (errorResponse: any) => {
        const errors = get(errorResponse, "networkError.result.errors");
        if (errors[0].message === "updateUnsuccessfully") {
          publishedAlert();
        }
      },
    });
    const [filterArrays, setFilterArrays] = useState([]);
    const [isFilterNotFound, setIsFilterNotFound] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const [isShow, setIsShow] = useState(false);

    const enrollment = useMemo(() => enrollmentQry?.enrollment, [
      enrollmentQry,
    ]);

    const {
      data: lastSyncEmployeeResponse,
      refetch: refetchLastSyncEmployee,
    } = useQuery(API.GET_SYNC_TRANSACTION, {
      fetchPolicy: "network-only",
    });

    const updatedAt = useMemo(() => {
      if (
        lastSyncEmployeeResponse &&
        lastSyncEmployeeResponse.getSyncTransaction
      ) {
        return formatDate(
          lastSyncEmployeeResponse.getSyncTransaction.lastSyncedEmployeeAt,
          "dd/MM/yyyy, HH:mm"
        );
      }
      return "";
    }, [lastSyncEmployeeResponse]);

    const dataTranslated = useDataTranslation(data?.getEmployees);
    const attendees = useMemo(() => {
      return groupBy(
        dataTranslated?.sort((a: any, b: any) => {
          const nameA = a.group.toUpperCase();
          const nameB = b.group.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
        (employee) => employee.group
      );
    }, [dataTranslated]);

    const handleFilter = useCallback(() => {
      if (!isEmpty(values.codeAndName)) {
        const filter = dataTranslated.filter(
          (employee: any) =>
            employee.code.includes(values.codeAndName) ||
            employee.nameTh.includes(values.codeAndName) ||
            employee.nameEn.includes(values.codeAndName)
        );
        const groupFilter = groupBy(filter, (filt) => filt.group);
        const arrayGroup = Object.entries(groupFilter).map(
          (groupFilt: any) => groupFilt[0]
        );
        setFilterArrays(filter);
        if (filter.length === 0) {
          setIsFilterNotFound(true);
        }
        setExpanded(arrayGroup);
      }
    }, [
      values,
      dataTranslated,
      setFilterArrays,
      setIsFilterNotFound,
      setExpanded,
    ]);

    const handleResetFilter = useCallback(() => {
      setValues({ codeAndName: "" });
      setFilterArrays([]);
      setIsFilterNotFound(false);
      setExpanded([]);
    }, [setValues, setFilterArrays, setIsFilterNotFound]);

    const handleNext = useCallback(async () => {
      try {
        await createAttendeeGroup({
          variables: { employees: data?.getEmployees, enrollmentId: id },
        });
      } catch (e) {}
    }, [data, createAttendeeGroup, id]);

    const handleUpdateData = useCallback(async () => {
      try {
        setIsShow(true);
        await syncEmployee({ variables: { id } });
      } catch (e) {
        setIsShow(false);
        notifyError(t(".syncFail"));
      }
    }, [syncEmployee, id, t]);

    const handleChange = useCallback(
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(
          isExpanded
            ? uniq([...expanded, panel])
            : expanded.filter((expand: any) => expand !== panel)
        );
      },
      [setExpanded, expanded]
    );

    const viewOnly = useMemo(() => {
      let valid = true;
      valid =
        valid && isEqual(enrollment?.state, EnumEnrollmentState.published);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.active);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.closed);
      return valid;
    }, [enrollment]);

    const columns = useMemo(
      () => [
        {
          width: 120,
          field: "code",
          headerName: t(".code") || "",
        },
        {
          width: 218,
          field: "name",
          headerName: t(".name") || "",
        },
        {
          width: 218,
          field: "department",
          headerName: t(".department") || "",
        },
        {
          width: 218,
          field: "position",
          headerName: t(".position") || "",
        },
      ],
      [t]
    );

    return {
      attendees,
      columns,
      loading,
      handleFilter,
      handleResetFilter,
      handleNext,
      handleUpdateData,
      filterArrays,
      isFilterNotFound,
      handleChange,
      expanded,
      updatedAt,
      isShow,
      viewOnly,
    };
  })
);

export const EnrollmentAttendee = enhancer(EnrollmentAttendeeComponent);
