import { compose, withHooks, withTranslation } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Modal } from "components";
import { gql, homePath, notifyError, notifySuccess, paths } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { useTranslation } from "react-i18next";

const RoleIndexPage = (props: any) => (
  <PageContent
    title={props.t(".title")}
    pageActions={props.pageActions}
    breadcrumbs={props.breadcrumbs}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        {props.t(".list")}
      </Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.roleEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
        includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ROLES: gql`
    query FETCH_ROLES {
      roles {
        id
        title
        description
        permissions
        createdAt
        updatedAt
        userAmount
      }
    }
  `,
  DELETE_ROLES: gql`
    mutation DELETE_ROLES($ids: [String!]!) {
      deleteRoles(input: { ids: $ids }) {
        id
        title
        permissions
        createdAt
        updatedAt
      }
    }
  `,
  FETCH_ROLE_OPTINS: gql`
    query FETCH_ROLE_OPTINS {
      roleOptions {
        permissionOptions {
          label
          value
        }
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({ prefix: "pages.main.roles.index" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useCallback, useQuery, useMutation, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_ROLES);
    const { data: roleData } = useQuery(API.FETCH_ROLE_OPTINS);
    const [deleteRoles] = useMutation(API.DELETE_ROLES);
    const { t } = props;
    useEffect(() => {
      refetch();
    }, [refetch]);

    const deleteRole = useCallback(
      async (params: any) => {
        const { id } = params.row;

        Modal.open({
          title: t(".deletedRole"),
          children: (
            <Box flex={1} mb={5} display="flex" flexDirection="row">
              <Typography variant="body1" color="#767676">
                {t(".deleteRole")}
              </Typography>
            </Box>
          ),
          cancelButtonLabel: t(".cancel"),
          okButtonLabel: t(".delete"),
          onOk: async ({ close }: any) => {
            await deleteRoles({ variables: { ids: [id] } });
            await refetch();
            close();
            notifySuccess(t(".deleteRoleSuccess"));
          },
        });
      },
      [deleteRoles, refetch, t]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.roles.map((role: any) => {
        return {
          ...role,
          userAmount:
            role.userAmount === 0 ? `${role.userAmount}` : role.userAmount,
          actions:
            role.userAmount > 0
              ? []
              : [
                  {
                    Icon: TrashIcon,
                    onClick: deleteRole,
                  },
                ],
        };
      });
    }, [loading, data, error, deleteRole]);

    const pageActions = useMemo(
      () => [
        {
          children: t(".addButton"),
          startIcon: <AddIcon />,
          onClick: () => paths.roleNewPath().push(),
          color: "primary",
          permittedRoles: ["PERMISSION_MANAGEMENT_EDIT"],
        },
      ],
      [t]
    );
    const title = t(".title");
    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        { path: null, label: title },
      ];
    }, [title, t]);
    const columns = useMemo(
      () => [
        {
          width: 200,
          field: "title",
          headerName: t(".title"),
        },
        {
          width: 400,
          field: "description",
          headerName: t(".description"),
        },
        {
          width: 120,
          field: "userAmount",
          headerName: t(".amount"),
        },
        {
          width: 180,
          field: "updatedAt",
          headerName: t(".updatedAt"),
          type: "date",
        },
        {
          width: 40,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ],
      [t]
    );

    return {
      tableData,
      loading,
      columns,
      pageActions,
      breadcrumbs,
    };
  })
);

export default enhancer(RoleIndexPage);
