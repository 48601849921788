import { compose, withHooks, withFormik, withTranslation } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  Notification,
  Select,
  Modal,
} from "components";
import { gql, homePath, paths, Yup } from "utils/helper";
import { makeStyles } from "@material-ui/core/styles";
import { useCallback } from "react";
import { AppColor } from "theme/app-color";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { t } from "i18next";

const AdminNewPage = (props: any) => (
  <PageContent
    title={props.t(".rootPath")}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Form>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4">{props.t(".header")}</Typography>

        <Box display="flex" mt={6}>
          <Field
            component={TextField.Email}
            name="email"
            label={props.t(".email")}
            required
            fullWidth
            mr={6}
          />
          <Field
            component={Select}
            name="roleId"
            type="text"
            label={props.t(".role")}
            required
            options={props.roleOptions}
            fullWidth
          />
        </Box>

        <Box display="flex" mt={10}>
          <Button
            width={74}
            p={0}
            mr={2}
            variant="outlined"
            onClick={props.onBack}
          >
            {props.t(".cancel")}
          </Button>

          <Button type="submit" width={74} p={0} variant="contained">
            {props.t(".send")}
          </Button>
        </Box>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  CREATE_ADMIN: gql`
    mutation CREATE_ADMIN($email: String!, $roleId: String) {
      createAdmin(input: { email: $email, roleId: $roleId }) {
        admin {
          code
          createdAt
          firstName
          id
          isSuperAdmin
          updatedAt
        }
      }
    }
  `,
  FETCH_ROLES: gql`
    query FETCH_ROLES {
      roles {
        id
        title
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      email: "",
      roleId: "",
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string().required(".invalidEmailSyntax"),
    }),
  }),
  withTranslation({ prefix: "pages.main.admins.new" }),
  withPreventLeaveDirtyForm(),
  withHooks((props: any, hooks: any) => {
    const { t, dirty } = props;
    const { useMutation, useMemo, useHandleSubmit, useQuery } = hooks;
    const [createAdmin] = useMutation(API.CREATE_ADMIN);
    const { data: roleData } = useQuery(API.FETCH_ROLES);

    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        { path: paths.adminsPath(), label: t(".rootPath") },
        { path: null, label: t(".title") },
      ];
    }, [t]);

    useHandleSubmit(
      async (values: any) => {
        const { email, roleId } = values;
        await createAdmin({ variables: { email, roleId } });
        paths.adminsPath().push();
        Notification.success(t(".notificationSuccess"));
      },
      [createAdmin]
    );

    const roleOptions = useMemo(
      () =>
        roleData?.roles?.map((role: any) => ({
          label: role.title,
          value: role.id,
        })),
      [roleData]
    );

    const onBack = useCallback(() => {
      paths.adminsPath().push();
    }, []);

    return { breadcrumbs, roleOptions, onBack };
  })
);

export default enhancer(AdminNewPage);
