import { compose, withHooks, withFormik, withTranslation } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  Button,
  Notification,
  TextField,
} from "components";
import { gql, homePath, paths } from "utils/helper";
import { makeStyles } from "@material-ui/core/styles";
import ErrorMessage from "components/common/ErrorMessage";
import Checkboxes from "components/common/Checkboxes";
import { AppColor } from "theme/app-color";
import { PERMISSIONS } from "constants/enums/permissions";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";

export const RoleNewPage = (props: any) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Form>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4">{props.t(".detail")}</Typography>
        <ErrorMessage with="100%" name="__error__" mt={6} />
        <Box mt={6}>
          <Box display="flex">
            <Box display="flex" flexDirection="column" width="100%">
              <Field
                component={TextField}
                name="title"
                type="text"
                label={props.t(".roleTitle")}
                required
                fullWidth
                fast={false}
                disabled={!props.hasEditPermission || props.isSuperAdminRole}
              />
              <Box mt={6}>
                <Field
                  component={TextField}
                  name="description"
                  type="text"
                  label={props.t(".description")}
                  fullWidth
                  fast={false}
                  disabled={!props.hasEditPermission || props.isSuperAdminRole}
                />
              </Box>
              <Box mt={10}>
                <Typography variant="h4">{props.t(".scope")}</Typography>
                <Checkboxes
                  label="การเข้าถึง"
                  name="permissions"
                  options={props.permissionOptions}
                  hasEditPermission={props.hasEditPermission}
                  isSuperAdminRole={props.isSuperAdminRole}
                  disabledPermissionRead={props.disabledPermissionRead}
                  disabledAdminRead={props.disabledAdminRead}
                  disabledEnrollmentRead={props.disabledEnrollmentRead}
                  disabledReportRead={props.disabledReportRead}
                  disabledInsuranceRead={props.disabledInsuranceRead}
                  disabledSettingRead={props.disabledSettingRead}
                />
              </Box>
            </Box>
          </Box>
          {props.hasEditPermission && !props.isSuperAdminRole && (
            <>
              <Button
                width={74}
                mt={4}
                mr={4}
                onClick={props.handleCancel}
                variant="outlined"
              >
                {props.t(".cancel")}
              </Button>
              <Button
                type="submit"
                width={74}
                mt={4}
                disabled={!props.enabledSubmitButton}
                variant="contained"
              >
                {props.t(".save")}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  FETCH_ROLE_OPTINS: gql`
    query FETCH_ROLE_OPTINS {
      roleOptions {
        roleOptions {
          label
          value
        }
        permissionOptions {
          label
          value
        }
      }
    }
  `,
  CREATE_ROLE: gql`
    mutation CREATE_ROLE(
      $title: String
      $permissions: [String!]
      $description: String
    ) {
      createRole(
        input: {
          title: $title
          permissions: $permissions
          description: $description
        }
      ) {
        role {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withPreventLeaveDirtyForm(),
  withTranslation({ prefix: "pages.main.roles.new" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMutation,
      useMemo,
      useHandleSubmit,
      useQuery,
      useEffect,
      useCallback,
    } = hooks;
    const { values, setValues, t } = props;
    const { data } = useQuery(API.FETCH_ROLE_OPTINS);
    const [createRole] = useMutation(API.CREATE_ROLE);

    const title = t(".addRole");

    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        { path: paths.rolesPath(), label: t(".role") },
        { path: null, label: title },
      ];
    }, [title, t]);

    useHandleSubmit(
      async (values: any) => {
        await createRole({ variables: { ...values } });
        paths.rolesPath().push();
        Notification.success("บันทึกสำเร็จ");
      },
      [createRole]
    );

    const { roleOptions, permissionOptions } = useMemo(() => {
      return data?.roleOptions ?? { roleOptions: [], permissionOptions: [] };
    }, [data]);

    const enabledSubmitButton =
      values?.permissions?.length > 0 && values?.title;

    const handleCancel = useCallback(() => {
      paths.rolesPath().push();
    }, []);

    const havePermission = useCallback(
      (permissions: any) => {
        return [permissions].every((perm: any) =>
          values?.permissions?.includes(perm)
        );
      },
      [values]
    );

    const disabledAdminRead = havePermission(PERMISSIONS.ADMIN_MANAGEMENT_EDIT);
    const disabledPermissionRead = havePermission(
      PERMISSIONS.PERMISSION_MANAGEMENT_EDIT
    );
    const disabledEnrollmentRead = havePermission(
      PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT
    );
    const disabledReportRead = havePermission(
      PERMISSIONS.REPORT_MANAGEMENT_EDIT
    );
    const disabledInsuranceRead = havePermission(
      PERMISSIONS.INSURANCE_MANAGEMENT_EDIT
    );
    const disabledSettingRead = havePermission(
      PERMISSIONS.SETTING_MANAGEMENT_EDIT
    );

    useEffect(() => {
      if (havePermission(PERMISSIONS.ADMIN_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.ADMIN_MANAGEMENT_READ))
          perms.push(PERMISSIONS.ADMIN_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      if (havePermission(PERMISSIONS.PERMISSION_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.PERMISSION_MANAGEMENT_READ))
          perms.push(PERMISSIONS.PERMISSION_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      if (havePermission(PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.ENROLLMENT_MANAGEMENT_READ))
          perms.push(PERMISSIONS.ENROLLMENT_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      if (havePermission(PERMISSIONS.REPORT_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.REPORT_MANAGEMENT_READ))
          perms.push(PERMISSIONS.REPORT_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      if (havePermission(PERMISSIONS.INSURANCE_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.INSURANCE_MANAGEMENT_READ))
          perms.push(PERMISSIONS.INSURANCE_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      if (havePermission(PERMISSIONS.SETTING_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.SETTING_MANAGEMENT_READ))
          perms.push(PERMISSIONS.SETTING_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.permissions]);

    return {
      title,
      breadcrumbs,
      roleOptions,
      permissionOptions,
      enabledSubmitButton,
      handleCancel,
      hasEditPermission: true,
      disabledAdminRead,
      disabledPermissionRead,
      disabledEnrollmentRead,
      disabledReportRead,
      disabledInsuranceRead,
      disabledSettingRead,
    };
  })
);

export default enhancer(RoleNewPage);
