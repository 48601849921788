import { gql } from "@apollo/client";
import { compose, withHooks, withStores } from "enhancers";
import paths from "routes/paths";

import appStore from "stores/appStore";

import { Redirect, Route, Switch } from "components";
import { BlankLayout, MenuLayout } from "layouts";

// import SetupPasswordPage from "pages/auth/SetupPassword";
// import LoginPageComponent from "pages/main/authentication/LoginPage";

import AdminEditPage from "pages/main/admins/edit";
import AdminIndexPage from "pages/main/admins/index";
import AdminNewPage from "pages/main/admins/new";

import RoleEditPage from "pages/main/roles/edit";
import RoleIndexPage from "pages/main/roles/index";
import RoleNewPage from "pages/main/roles/new";

import SettingsIndexPage from "pages/main/settings";

import { getLoggingAd, getRefreshToken, getToken } from "api";
import LanguagesDropdown from "components/common/LanguagesDropdown";
import { LoginPage } from "pages/main/authentication/LoginPage";
import { HandleAdRedirectPage } from "pages/main/authentication/HandleAdRedirectPage";
import { InsuranceFormPage } from "pages/main/insurance/InsuranceForm";
import { InsuranceDetailPage } from "pages/main/insurance/detail";

import { useMsal } from "@azure/msal-react";
import { PERMISSIONS } from "constants/enums/permissions";
import { EnrollmentPage } from "pages/main/enrollment";
import { EnrollmentDetailPage } from "pages/main/enrollment/detail";
import { EnrollmentInsurancePage } from "pages/main/enrollment/enrollmentInsurance";
import { InsuranceLandingPage } from "pages/main/insurance";
import ViewInsurancePage from "pages/main/insurance/insurancePackages/ViewInsurance";
import InsurancePackagesEditPage from "pages/main/insurance/insurancePackages/edit";
import InsurancePackagesNewPage from "pages/main/insurance/insurancePackages/new";
import { ReportPage } from "pages/main/report";
import MarketplaceReportPage from "pages/main/marketplaceReport";

// prettier-ignore
const InitialPages = () => (
  <BlankLayout>
    <LanguagesDropdown />
    <Switch>
      <Route path={paths.signUpPath()} exact component={LoginPage} />

      {/* <Route path={paths.setupPasswordPath()}   exact component={SetupPasswordPage} /> */}

      <Redirect to={paths.signUpPath()} />
    </Switch>
  </BlankLayout>
);

// prettier-ignore
const GuestPages = () => (
  <BlankLayout>
    <LanguagesDropdown />
    <Switch>
      <Route path={paths.signInPath()} exact component={LoginPage} />
      {/* <Route path={paths.setupPasswordPath()}  exact component={SetupPasswordPage} /> */}
      {/* <Route path={paths.resetPasswordPath()}  exact component={ResetPasswordPage} /> */}

      <Redirect to={paths.signInPath()} />
    </Switch>
  </BlankLayout>
);

// prettier-ignore
const HandleAdRedirectPages = () => (
  <BlankLayout>
    <Switch>
      <Route path={paths.signInPath()} exact component={HandleAdRedirectPage} />
      <Redirect to={paths.signInPath()} />
    </Switch>
  </BlankLayout>
);

// prettier-ignore
const MainPages = (props: any) => (
  <Switch>
    <Route
      path={paths.adminNewPath()}
      exact
      layout={MenuLayout}
      component={AdminNewPage}
      permittedRoles={["ADMIN_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.adminsPath()}
      exact
      layout={MenuLayout}
      component={AdminIndexPage}
      permittedRoles={["ADMIN_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.adminEditPath(":id")}
      exact
      layout={MenuLayout}
      component={AdminEditPage}
      permittedRoles={["ADMIN_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.rolesPath()}
      exact
      layout={MenuLayout}
      component={RoleIndexPage}
      permittedRoles={["PERMISSION_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.roleNewPath()}
      exact
      layout={MenuLayout}
      component={RoleNewPage}
      permittedRoles={["PERMISSION_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.roleEditPath(":id")}
      exact
      layout={MenuLayout}
      component={RoleEditPage}
      permittedRoles={["PERMISSION_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.settingsPath()}
      exact
      layout={MenuLayout}
      component={SettingsIndexPage}
      permittedRoles={["SETTING_MANAGEMENT_READ"]}
    />

    <Route
      path={paths.insurancePackageNewPath(":insuranceId")}
      exact
      layout={MenuLayout}
      component={InsurancePackagesNewPage}
      permittedRoles={[PERMISSIONS.INSURANCE_MANAGEMENT_READ]}
    />
    <Route
      path={paths.insurancePackageEditPath(":insuranceId", ":id")}
      exact
      layout={MenuLayout}
      component={InsurancePackagesEditPage}
      permittedRoles={[PERMISSIONS.INSURANCE_MANAGEMENT_READ]}
    />
    <Route
      path={paths.insurancePackageViewInsurancePath(":insuranceId", ":id")}
      exact
      layout={MenuLayout}
      component={ViewInsurancePage}
      permittedRoles={[PERMISSIONS.INSURANCE_MANAGEMENT_READ]}
    />
    <Route
      path={paths.addInsurancePath(":id")}
      exact
      layout={MenuLayout}
      component={InsuranceFormPage}
      permittedRoles={[PERMISSIONS.INSURANCE_MANAGEMENT_READ]}
    />
    <Route
      path={paths.editInsurancePath(":id", ":insuranceId")}
      exact
      layout={MenuLayout}
      component={InsuranceFormPage}
      permittedRoles={[PERMISSIONS.INSURANCE_MANAGEMENT_READ]}
    />
    <Route
      path={paths.insuranceDetailPath(":id")}
      layout={MenuLayout}
      component={InsuranceDetailPage}
      permittedRoles={[PERMISSIONS.INSURANCE_MANAGEMENT_READ]}
    />
    <Route
      path={paths.insurancePath()}
      exact
      layout={MenuLayout}
      component={InsuranceLandingPage}
      permittedRoles={[PERMISSIONS.INSURANCE_MANAGEMENT_READ]}
    />

    <Route
      path={paths.enrollmentPath()}
      exact
      layout={MenuLayout}
      component={EnrollmentPage}
      permittedRoles={[PERMISSIONS.ENROLLMENT_MANAGEMENT_READ]}
    />
    <Route
      path={paths.enrollmentDetailPath(":id")}
      layout={MenuLayout}
      component={EnrollmentDetailPage}
      permittedRoles={[PERMISSIONS.ENROLLMENT_MANAGEMENT_READ]}
    />
    <Route
      path={paths.enrollmentInsurancePath(":id", ":insuranceId")}
      exact
      layout={MenuLayout}
      component={EnrollmentInsurancePage}
      permittedRoles={[PERMISSIONS.ENROLLMENT_MANAGEMENT_READ]}
    />
    <Route
      path={paths.reportPath()}
      exact
      layout={MenuLayout}
      component={ReportPage}
      permittedRoles={[PERMISSIONS.REPORT_MANAGEMENT_READ]}
    />
    <Route
      path={paths.marketplaceReportPath()}
      exact
      layout={MenuLayout}
      component={MarketplaceReportPage}
      permittedRoles={[PERMISSIONS.REPORT_MANAGEMENT_READ]}
    />
    <Redirect withPermissions to={paths.enrollmentPath()} />
  </Switch>
);

interface RoutesProps {
  initialized: boolean;
  hasFirstAdmin: boolean;
  isAuthorized: boolean;
  isAdmin: boolean;
  hasTokens: boolean;
  isLoggingAD: boolean;
}

// prettier-ignore
const Routes = (props: RoutesProps) => {
  console.log('Before render props:', props)
  // return <MainPages />;
  if (!props.initialized) {
    console.log('Render null')
    return null;
  } else if (!props.hasFirstAdmin) {
    console.log("Render InitialPages");
    return <InitialPages />;
  } else if (props.isLoggingAD) {
    return <HandleAdRedirectPages />;
  } else if (!props.isAuthorized) {
    console.log("Render GuestPages");
    return <GuestPages />;
  } else {
    console.log("Render MainPages");
    return <MainPages />;
  }
};

export const API = {
  GET_APP_INFO: gql`
    query GET_APP_INFO {
      info {
        hasFirstAdmin
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentBackofficeUser {
        id
        firstName
        lastName
        ownerType
        email
        admin {
          id
          code
          firstName
          lastName
        }
        role {
          title
          permissions
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: { appStore: { currentUser: any } }) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUser } = props;
    const { useQuery, useMemo, useEffect, useLazyQuery, useState } = hooks;
    const { accounts, instance, inProgress } = useMsal();
    const [isSetCurrentUser, setIsSetCurrentUser] = useState(false);

    const appInfo = useQuery(API.GET_APP_INFO);
    const [fetchCurrentUser, newCurrentUserResponse] = useLazyQuery(
      API.GET_CURRENT_USER,
      {
        onCompleted: (data: any) => {
          appStore.setCurrentUser(data.getCurrentBackofficeUser);
          setIsSetCurrentUser(true);
        },
      }
    );

    const token = getToken();
    const refreshToken = getRefreshToken();

    useEffect(() => {
      if (token && refreshToken) {
        console.log("Fetching current user");
        fetchCurrentUser();
      }
    }, [fetchCurrentUser, token, refreshToken]);
    const { loading, data, error } = newCurrentUserResponse || {};

    const isLoggingAD = getLoggingAd();

    const initialized =
      !appInfo.loading &&
      ((token && refreshToken && isSetCurrentUser) ||
        (!token && !refreshToken)) &&
      !newCurrentUserResponse.loading;
    const isAuthorized = currentUser && isSetCurrentUser;
    const hasFirstAdmin = !!appInfo.data?.info.hasFirstAdmin;
    const hasTokens = token && refreshToken;

    const ownerType = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data?.getCurrentBackofficeUser?.ownerType;
    }, [loading, data, error]);

    useEffect(() => {
      const $splashScreen = document.getElementById("splash-screen");
      if ($splashScreen) {
        const display = initialized ? "none" : "";
        $splashScreen.style.display = display;
      }
    }, [initialized]);

    useEffect(() => {
      appStore.setHasFirstAdmin(hasFirstAdmin);
    }, [hasFirstAdmin]);

    const isAdmin = ownerType === "Admin";
    console.log("On hooks:", {
      initialized,
      isAuthorized,
      hasFirstAdmin,
      isAdmin,
      hasTokens,
      isLoggingAD,
      accounts,
      instance,
      inProgress,
    });
    return {
      initialized,
      isAuthorized,
      hasFirstAdmin,
      isAdmin,
      hasTokens,
      isLoggingAD,
    };
  })
);

export default enhancer(Routes);
