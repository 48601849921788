import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { compose, withFormik, withHooks, withStores } from "enhancers";
import { get, isFunction, isString } from "lodash";
import appStore from "stores/appStore";
import { AppColor } from "theme/app-color";
import Button from "./Button";
import Typography from "./Typography";

const ModalComponent = ({
  isShow,
  title,
  children,
  cancelButtonLabel,
  okButtonLabel,
  disabled,
  fullWidth,
  maxWidth,
  onOk,
  onCancel,
  onClose,
  onDelete,
  hideFooter,
  okButtonVariant = "text",
  cancelButtonVariant = "text",
  okButtonColor,
  ...rest
}: any) => (
  <Dialog
    open={isShow}
    onClose={disabled ? undefined : onClose}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    {...rest}
  >
    <DialogTitle>
      <Typography variant="h4" color={AppColor["Text/Black"]}>
        {title}
      </Typography>
    </DialogTitle>
    <DialogContent>
      {isString(children) ? (
        <DialogContentText>{children}</DialogContentText>
      ) : isFunction(children) ? (
        children(rest)
      ) : (
        children
      )}
    </DialogContent>
    {!hideFooter ? (
      <DialogActions>
        {onCancel && (
          <Button
            variant={cancelButtonVariant}
            onClick={onCancel}
            color="light"
            size="small"
            disabled={disabled}
            style={{
              marginRight: 16,
              color: "#767676",
            }}
          >
            {cancelButtonLabel}
          </Button>
        )}
        {onOk && (
          <Button
            variant={okButtonVariant}
            onClick={() => onOk(rest)}
            style={{
              color: okButtonColor
                ? okButtonColor
                : okButtonLabel === "ลบ" ||
                  okButtonLabel === "ลบข้อมูล" ||
                  okButtonLabel === "ปฏิเสธและแจ้งเหตุผล" ||
                  okButtonLabel === "ออกจากระบบ"
                ? "#F34336"
                : okButtonLabel === "ยืนยันการจองคิว" ||
                  okButtonLabel === "ยืนยัน" ||
                  okButtonLabel === "ปิด"
                ? "#2296F3"
                : "primary",
              border: `1px solid ${okButtonColor}`,
            }}
            size="small"
            disabled={disabled}
          >
            {okButtonLabel}
          </Button>
        )}
      </DialogActions>
    ) : null}
  </Dialog>
);

const enhancer = compose(
  withStores((stores: any, props: any) =>
    props.storeName ? get(stores, props.storeName) : {}
  ),
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { storeName, dispatch, ...rest } = props;
    const { useEffect } = hooks;
    useEffect(() => {
      if (!rest.isShow) {
        rest.resetForm();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest.isShow, rest.resetForm]);

    return rest;
  })
);

const Modal = enhancer(ModalComponent);

Modal.open = appStore.openMainModal;
Modal.close = appStore.closeMainModal;
Modal.alert = appStore.openAlertModal;
Modal.confirm = appStore.openConfirmModal;

export default Modal;
