import { useMutation } from "@apollo/client";
import { Notification } from "components";
import { get, isEmpty, set } from "lodash";

const useCustomMutation = (api, props, mutationProps) => {
  const { setErrors, t } = props;
  return useMutation(api, {
    ...mutationProps,
    onError: (error) => {
      if (!mutationProps?.skipSetError) {
        if (error.networkError.statusCode === 422 && setErrors) {
          const errors = get(error, "networkError.result.errors");
          const fieldError = {};
          let __error__ = "";
          for (const e of errors) {
            const originalError = get(e, "extensions.originalError", null);
            if (!originalError) {
              continue;
            }

            if (originalError instanceof Array) {
              for (const { field, message } of originalError) {
                if (field) {
                  set(fieldError, field, message);
                } else {
                  __error__ += `${message}\n`;
                }
              }
            }
          }
          setErrors({ ...fieldError, __error__ });
          if (isEmpty(fieldError))
            Notification.error(
              t(
                get(
                  error,
                  "networkError.result.errors[0].message",
                  "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน"
                )
              )
            );
        } else if (error.networkError.statusCode === 422) {
          Notification.error(
            t(
              get(
                error,
                "networkError.result.errors[0].message",
                "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน"
              )
            )
          );
        }
      }

      if (mutationProps?.onError) {
        mutationProps.onError(error);
      } else {
        throw new Error(error.toString());
      }
    },
  });
};

export default useCustomMutation;
