import {
  Button,
  FieldArray,
  Form,
  Modal,
  Notification,
  Paper,
  Typography,
} from "components";
import Authorize from "components/Authorize";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";
import { EnumEnrollmentSubState } from "constants/enums/enrollment-sub-state";
import { PERMISSIONS } from "constants/enums/permissions";
import { compose, withFormik, withHooks, withTranslation } from "enhancers";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { TFunction } from "i18next";
import { get, isEmpty, isEqual } from "lodash";
import { useCallback, useEffect } from "react";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { Yup, gql, publishedAlert } from "utils/helper";
import { handleNavigateEnrollmentStep } from "../enrollmentRoutes";
import GroupPanel from "./GroupPanel";

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface IPageAttendeeGroupProps {
  viewOnly: boolean;
  isValid: boolean;
  hasSubmissionAction: boolean;
  t: TFunction;
}

const PageAttendeeGroup = ({
  t,
  viewOnly,
  isValid,
  hasSubmissionAction,
}: IPageAttendeeGroupProps) => (
  <Paper px={4} py={6}>
    <TitleContainer>
      <Typography variant="h4">{t(".title")}</Typography>
    </TitleContainer>

    <Form style={{ width: "100%", marginTop: "24px" }}>
      <FieldArray name="attendeeGroups" component={GroupPanel} />
      {!viewOnly && (
        <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
          <Button mt={10} type="submit" disabled={!isValid}>
            {hasSubmissionAction ? t(".saveAndNext") : t(".save")}
          </Button>
        </Authorize>
      )}
    </Form>
  </Paper>
);

const API = {
  UPDATE_ENROLLMENT: gql`
    mutation UPDATE_ENROLLMENT(
      $id: String!
      $page: String!
      $attendeeGroups: [JSON!]
    ) {
      updateEnrollment(
        input: { id: $id, attendeeGroups: $attendeeGroups, page: $page }
      ) {
        id
        startDate
        endDate
        existingAttendeeSelectionPeriodInDays
        incomingAttendeeSelectionPeriodInDays
        subState
        attendeeGroups {
          id
        }
      }
    }
  `,
  FETCH_ATTENDEE_GROUPS: gql`
    query FETCH_ATTENDEE_GROUPS($enrollmentId: String) {
      attendeeGroups(input: { enrollmentId: $enrollmentId }) {
        id
        titleTh
        titleEn
        point
        masterBenefitInsurances {
          id
          type
          masterBenefitInsurancePlans {
            id
            isDefault
            masterInsurancePlan {
              id
              nameTh
              nameEn
              premium
              remarkTh
              remarkEn
              description
              insuranceTypeId
              insuranceType {
                id
                nameTh
                nameEn
              }
            }
          }
          masterBenefitInsurancePackages {
            id
            isDefault
            masterInsurancePackage {
              id
              nameTh
              nameEn
              remarkTh
              remarkEn
              packagePricingType
              insurancePackagesInsurancePlans {
                id
                insurance {
                  id
                  nameTh
                  nameEn
                  remarkTh
                  remarkEn
                  premium
                  description
                }
              }
            }
          }
        }
        file {
          url
          fileName
        }
      }
    }
  `,
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      enrollment(id: $id) {
        year
        state
        subState
        startDate
        endDate
        existingAttendeeSelectionPeriodInDays
        incomingAttendeeSelectionPeriodInDays
        updatedAt
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      attendeeGroups: Yup.array()
        .nullable(true)
        .of(
          Yup.object().shape({
            // point: Yup.number()
            //   .nullable(true)
            //   .required(".required")
            //   .typeError(".typeNumber")
            //   .moreThan(0, "ต้องใส่ตัวเลขที่ไม่น้อยกว่า 0 เท่านั้น"),
            masterBenefitInsurances: Yup.array()
              .nullable(true)
              .of(
                Yup.object().shape({
                  type: Yup.string().required(),
                })
              ),
          })
        ),
    }),
  }),
  withPreventLeaveDirtyForm(),
  withTranslation({ prefix: "pages.main.enrollment.attendeeGrouping" }),
  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useMutation,
      useParams,
      useQuery,
      useMemo,
      useDataTranslation,
    } = hooks;
    const { setInitialValues, isValid, t } = props;
    const { id } = useParams();

    const { data: enrollmentQry } = useQuery(API.GET_ENROLLMENT, {
      variables: { id },
      fetchPolicy: "network-only",
    });

    const enrollment = useMemo(() => enrollmentQry?.enrollment, [
      enrollmentQry,
    ]);

    const { data: attendeeGroupsResponse } = useQuery(
      API.FETCH_ATTENDEE_GROUPS,
      {
        variables: { enrollmentId: id },
        fetchPolicy: "network-only",
      }
    );
    const attendeeGroupsTranslated = useDataTranslation(attendeeGroupsResponse);

    const hasSubmissionAction = useMemo(() => {
      return (
        enrollment?.subState === EnumEnrollmentSubState.attendee_groups_editing
      );
    }, [enrollment]);

    const [updateEnrollment] = useMutation(API.UPDATE_ENROLLMENT, {
      onCompleted: (data: any) => {
        Notification.success(t(".saveSuccess"));
        if (hasSubmissionAction)
          handleNavigateEnrollmentStep(id, data?.updateEnrollment?.subState);
      },
      skipSetError: true,
      onError: (errorResponse: any) => {
        const errors = get(errorResponse, "networkError.result.errors");
        if (errors[0].message === "updateUnsuccessfully") {
          publishedAlert();
        } else {
          const errorsResponse =
            errors[0].extensions.originalError[0].errorResponse;
          Modal.alert({
            title: t(".titleError"),
            children: (
              <>
                <div style={{ marginBottom: "20px", padding: "0px" }}>
                  <Typography
                    variant="Body/16"
                    color={AppColor["Other/Danger"]}
                  >
                    {t(".errorDetail1")}
                  </Typography>
                  <br />
                  <br />
                  <Typography
                    variant="Body/16"
                    color={AppColor["Text/Dark Grey"]}
                  >
                    {t(".errorDetail2")}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "24px",
                  }}
                >
                  <ul>
                    {errorsResponse.map((item: any) => (
                      <li style={{ color: AppColor["Text/Dark Grey"] }}>
                        <Typography
                          variant="Body/16"
                          color={AppColor["Text/Dark Grey"]}
                        >
                          {item.title}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            ),
            onCancel: null,
            okButtonLabel: t(".ok"),
          });
        }
      },
    });

    const attendeeGroups = useMemo(() => {
      if (attendeeGroupsTranslated) {
        return attendeeGroupsTranslated.attendeeGroups.map((group: any) => {
          return {
            ...group,
            title: group.title,
            type: group.masterBenefitInsurances[0]?.type,
          };
        });
      }
      return [];
    }, [attendeeGroupsTranslated]);

    const viewOnly = useMemo(() => {
      let valid = true;
      valid =
        valid && isEqual(enrollment?.state, EnumEnrollmentState.published);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.active);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.closed);
      return valid;
    }, [enrollment]);

    const changeMasterBenefitInsurancePlans = useCallback(
      (selectedInsurances: any[] = []) => {
        const cleanValues = selectedInsurances.map((insurance: any) => ({
          id: insurance.id,
          isDefault: insurance.isDefault,
          masterInsurancePlan: {
            id: insurance.masterInsurancePlan.id,
            premium: insurance.masterInsurancePlan.premium,
            insuranceTypeId: insurance.masterInsurancePlan.insuranceTypeId,
          },
        }));
        return cleanValues;
      },
      []
    );

    const changeMasterBenefitInsurancePackages = useCallback(
      (selectedInsurances: any[] = []) => {
        const cleanValues = selectedInsurances.map((insurance: any) => ({
          id: insurance.id,
          isDefault: insurance.isDefault,
          masterInsurancePackage: {
            id: insurance.masterInsurancePackage.id,
          },
        }));
        return cleanValues;
      },
      []
    );

    const covertAttendeeGroup = (group: any) => {
      const masterBenefitInsurances = group.masterBenefitInsurances
        ? {
            id: group.masterBenefitInsurances[0].id,
            type: group.masterBenefitInsurances[0].type,
            masterBenefitInsurancePlans: changeMasterBenefitInsurancePlans(
              group.masterBenefitInsurances[0].masterBenefitInsurancePlans
            ),
            masterBenefitInsurancePackages: changeMasterBenefitInsurancePackages(
              group.masterBenefitInsurances[0].masterBenefitInsurancePackages
            ),
          }
        : null;

      return {
        file: group.file,
        id: group.id,
        title: group.title,
        point: group.point,
        masterBenefitInsurances: [
          {
            ...masterBenefitInsurances,
          },
        ],
      };
    };

    useHandleSubmit(
      async (values: any) => {
        if (enrollment) {
          const variables = {
            id,
            attendeeGroups: values.attendeeGroups.map((group: any) =>
              covertAttendeeGroup(group)
            ),
            page: "grouping",
          };
          console.log(variables);

          await updateEnrollment({
            variables,
          });
        }
      },
      [enrollment]
    );

    useEffect(() => {
      if (!isEmpty(attendeeGroups)) {
        setInitialValues({ attendeeGroups });
      }
    }, [attendeeGroups, setInitialValues]);

    return {
      viewOnly,
      isValid,
      hasSubmissionAction,
    };
  })
);

export default enhancer(PageAttendeeGroup);
