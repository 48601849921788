import { GridColDef } from "@material-ui/data-grid";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import {
  Box,
  Button,
  Divider,
  Modal,
  Notification,
  Radio,
  Table,
  Typography,
} from "components";
import { MaterialIcon } from "components/common/MaterialIcon";
import { compose, withAuthorize, withHooks, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import { map, orderBy } from "lodash";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { InsuranceSelectorTable } from "./InsuranceSelectorTable";

import { ReactComponent as CheckedRadioBoxIcon } from "assets/icon/checked_radio_box_icon.svg";
import { ReactComponent as UncheckRadioBoxIcon } from "assets/icon/uncheck_radio_box_icon.svg";
import Authorize from "components/Authorize";
import { PERMISSIONS } from "constants/enums/permissions";

const AddButton = styled(Button)`
  min-width: 151px;
`;

interface InsuranceListProps {
  label: string;
  isAddModalOpen: boolean;
  masterInsurancePlansOptions: any[];
  handleClickAddInsurance: () => void;
  handleCloseAddModal: () => void;
  handleConfirmSelection: (values: any[]) => void;
  t: TFunction;
  tableData: [];
  columns: GridColDef[];
  viewOnly: boolean;
}

export const InsuranceListComponent = ({
  label,
  isAddModalOpen,
  masterInsurancePlansOptions,
  handleConfirmSelection,
  handleCloseAddModal,
  t,
  ...props
}: InsuranceListProps) => (
  <Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="body2">
        {t(".insuranceTypeHeader", { type: label })}
      </Typography>
      <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
        {!props.viewOnly && (
          <AddButton onClick={props.handleClickAddInsurance} variant="outlined">
            <MaterialIcon name="Add" mr={4} />
            {t(".add")}
          </AddButton>
        )}
      </Authorize>
    </Box>

    {props.tableData.length > 0 ? (
      <Box mt={4}>
        <Table
          className="EnrollmentInsuranceListTable"
          columns={props.columns}
          rows={props.tableData}
          density="compact"
          autoHeight
          rowsPerPageOptions={[5, 10]}
          style={{ minHeight: "40px" }}
          hideFooterPagination
          hideFooterRowCount
          disableSelectionOnClick
        />
      </Box>
    ) : (
      <Divider my={4} />
    )}
    <InsuranceSelectorTable
      isShow={isAddModalOpen}
      masterInsurancePlans={masterInsurancePlansOptions}
      onSelectionChange={handleConfirmSelection}
      onCancel={handleCloseAddModal}
    />
  </Box>
);

const enhancer = compose(
  withAuthorize(),
  withTranslation({
    prefix: "pages.main.enrollment.attendeeGrouping.InsuranceList",
  }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useCallback, useState } = hooks;
    const {
      label,
      id: insuranceTypeId,
      t,
      viewOnly,
      masterBenefitInsurancePlans = [],
      masterInsurancePlans = [],
      hasPermission,
      handleChangeMasterBenefitInsurancePlans,
    } = props;

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const hasEditPermission = useMemo(
      () => hasPermission([PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]),
      [hasPermission]
    );

    const changeInsurances = useCallback(
      (selectedInsurances: any[]) => {
        handleChangeMasterBenefitInsurancePlans(
          selectedInsurances,
          insuranceTypeId
        );
      },
      [handleChangeMasterBenefitInsurancePlans, insuranceTypeId]
    );

    const adjustIsDefaultBeforeSet = useCallback(
      (selected: any) => {
        console.log(selected);

        const hasAtLeastOneDefault = selected.find(
          (plan: any) => plan.isDefault
        );
        if (!hasAtLeastOneDefault) {
          const adjusted = selected.map((plan: any, ind: number) => ({
            ...plan,
            isDefault: ind === 0,
          }));
          changeInsurances(adjusted);
        } else {
          changeInsurances(selected);
        }
      },
      [changeInsurances]
    );

    const handleConfirmDelete = useCallback(
      (id: string) => {
        const filtered = masterBenefitInsurancePlans.filter(
          (insurance: any) => insurance.id !== id
        );
        adjustIsDefaultBeforeSet(filtered);
        Notification.notify(t(".deleteSuccess"));
      },
      [masterBenefitInsurancePlans, adjustIsDefaultBeforeSet, t]
    );

    const handleClickDeleteInsurance = useCallback(
      (props: any) => {
        const name = props.row.name;
        Modal.open({
          title: t(".deleteInsuranceModalTitle"),
          children: (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                  {t(".deleteInsuranceModalInfo1")} &nbsp;
                </Typography>
                <Typography variant="body2" color={AppColor["Text/Black"]}>
                  {name} &nbsp;
                </Typography>
                <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                  {t(".deleteModalInfo2")}
                </Typography>
              </div>
            </>
          ),
          cancelButtonLabel: t(".close"),
          okButtonLabel: t(".confirmDelete"),
          onOk: ({ close }: any) => {
            close();
            handleConfirmDelete(props.id);
          },
        });
      },
      [t, handleConfirmDelete]
    );

    const handleSetDefault = useCallback(
      (id: any) => {
        const result = masterBenefitInsurancePlans.map((insurance: any) => {
          return { ...insurance, isDefault: insurance.id === id };
        });
        changeInsurances(result);
      },
      [changeInsurances, masterBenefitInsurancePlans]
    );

    const tableData = useMemo(() => {
      return (
        masterBenefitInsurancePlans.map((data: any) => ({
          id: data.id,
          isDefault: data.isDefault,
          name: data.masterInsurancePlan.name,
          remark: data.masterInsurancePlan.remark,
          actions:
            !hasEditPermission || viewOnly
              ? []
              : [
                  {
                    Icon: TrashIcon,
                    onClick: handleClickDeleteInsurance,
                  },
                ],
        })) || []
      );
    }, [
      handleClickDeleteInsurance,
      viewOnly,
      hasEditPermission,
      masterBenefitInsurancePlans,
    ]);

    const columns: GridColDef[] = useMemo(
      (): GridColDef[] => [
        {
          width: 250,
          field: "name",
          headerName: t(".insuranceName") || "",
        },
        {
          width: 250,
          field: "remark",
          headerName: t(".remark") || "",
        },
        {
          width: 90,
          field: "isDefault",
          headerName: t(".default") || "",
          filterable: false,
          sortable: false,
          renderCell: (params) => (
            <Radio
              checked={params.row.isDefault}
              icon={<UncheckRadioBoxIcon />}
              checkedIcon={<CheckedRadioBoxIcon />}
              disabled={!hasEditPermission || viewOnly}
              onClick={() => handleSetDefault(params.id)}
            />
          ),
        },
        {
          width: 70,
          field: "actions",
          headerName: t(".delete"),
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ],
      [t, handleSetDefault, hasEditPermission, viewOnly]
    );

    const handleConfirmSelection = useCallback(
      (values: any) => {
        adjustIsDefaultBeforeSet([...masterBenefitInsurancePlans, ...values]);
        setIsAddModalOpen(false);
      },
      [masterBenefitInsurancePlans, adjustIsDefaultBeforeSet]
    );

    const handleCloseAddModal = useCallback(() => {
      setIsAddModalOpen(false);
    }, []);

    const handleClickAddInsurance = useCallback(async () => {
      setIsAddModalOpen(true);
    }, []);

    const masterInsurancePlansOptions = useMemo(() => {
      const selectedIds = map(
        masterBenefitInsurancePlans,
        "masterInsurancePlan.id"
      );
      return orderBy(
        masterInsurancePlans.filter(
          (plan: any) =>
            plan.insuranceTypeId === insuranceTypeId &&
            !selectedIds.includes(plan.id)
        ),
        "createdAt",
        "asc"
      );
    }, [insuranceTypeId, masterInsurancePlans, masterBenefitInsurancePlans]);

    return {
      label,
      handleClickAddInsurance,
      tableData,
      columns,
      viewOnly,
      isAddModalOpen,
      masterInsurancePlansOptions,
      handleConfirmSelection,
      handleCloseAddModal,
    };
  })
);

export const InsuranceList = enhancer(InsuranceListComponent);
