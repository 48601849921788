import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Form,
  Modal,
  Notification,
  Paper,
  Table,
  Typography,
} from "components";
import Authorize from "components/Authorize";
import { MaterialIcon } from "components/common/MaterialIcon";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";
import { EnumEnrollmentSubState } from "constants/enums/enrollment-sub-state";
import { PERMISSIONS } from "constants/enums/permissions";
import { CrossStorageClient } from "cross-storage";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import env, { appEnv } from "env";
import { TFunction } from "i18next";
import { get, isEqual, uniq, uniqBy } from "lodash";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import {
  forceDownload,
  formatDate,
  gql,
  notifySuccess,
  paths,
  publishedAlert,
  toCurrency,
} from "utils/helper";
import { handleNavigateEnrollmentStep } from "../enrollmentRoutes";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Loader from "components/Loader";

// TODO: แยก Banner Warning ออกมาเก็บไว้ใน Components

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OutlinedButton = styled(Button)`
  height: 34px;
`;

const HeadIcon = styled(MaterialIcon)`
  font-size: 24px;
`;

const TableBox = styled(Box)`
  border: 1px solid ${AppColor["Background/Dark Grey"]};
  padding: 16px 16px 0px 16px;
  border-radius: 8px;
`;

interface PeriodSettingProps {
  viewOnly: boolean;
  updatedAt: string;
  attendeeGroups: [any];
  handleChange: Function;
  expandedList: [any];
  t: TFunction;
  isPending: boolean;
  enrollment: any;
  mapType: (type: string) => any;
  employeeTableColumns: [any];
  customTableColumns: [any];
  packageTableColumns: [any];
  employeeTableData: (title: any) => [any];
  getInsuranceTypes: (masterBenefitInsurancePlans: [any]) => [any];
  changeTab: (id: any, tabId: any, symbol: any, name: any) => void;
  tab: [any];
  customTableData: (masterBenefitInsurancePlans: [any], symbol: any) => [any];
  packageTableData: (masterBenefitInsurancePackages: [any], id: any) => [any];
  editPeriodSetting: () => void;
  editGrouping: () => void;
  handlePublish: () => void;
  handleClickPreview: (attendeeGroup: any) => void;
  download: (file: any) => void;
  isShowLoading: boolean;
}

const PublishEnrollment = (props: PeriodSettingProps) => (
  <>
    <Paper px={4} py={6}>
      <TitleContainer>
        <Typography variant="h4">{props.t(".title")}</Typography>
      </TitleContainer>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        px={3}
        py={3}
        border={AppColor["Other Light/Warning"]}
        borderRadius={4}
        bgcolor={AppColor["Other Light/Warning"]}
        mt={4}
      >
        <MaterialIcon
          name="ReportProblemOutlined"
          style={{ color: AppColor["Text/Primary"] }}
        />
        <Typography variant="body1" ml={2} color={AppColor["Text/Primary"]}>
          {props.t(".warningMessage")}
        </Typography>
      </Box>
    </Paper>

    <Paper px={4} py={6} mt={4}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" mr={2}>
            <HeadIcon
              name="WatchLaterOutlined"
              htmlColor={AppColor["Secondary/Hover"]}
            />
          </Box>
          <Typography variant="h6" color={AppColor["Secondary/Hover"]}>
            {props.t(".period")}
          </Typography>
        </Box>
        <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
          {!props.viewOnly && (
            <OutlinedButton
              variant="outlined"
              onClick={props.editPeriodSetting}
            >
              <HeadIcon name="EditOutlined" style={{ marginRight: "10px" }} />
              {props.t(".edit")}
            </OutlinedButton>
          )}
        </Authorize>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
        <Box display="flex" flex={1}>
          <Typography variant="body1" fontWeight="bold">
            {props.t(".year")}
          </Typography>
        </Box>
        <Box display="flex" flex={1}>
          <Typography variant="body1">{props.enrollment.year}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
        <Box display="flex" flex={1}>
          <Typography variant="body1" fontWeight="bold">
            {props.t(".startDate")}
          </Typography>
        </Box>
        <Box display="flex" flex={1}>
          <Typography variant="body1">
            {formatDate(props.enrollment.startDate, "dd/MM/yyyy")}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
        <Box display="flex" flex={1}>
          <Typography variant="body1" fontWeight="bold">
            {props.t(".endDate")}
          </Typography>
        </Box>
        <Box display="flex" flex={1}>
          <Typography variant="body1">
            {formatDate(props.enrollment.endDate, "dd/MM/yyyy")}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
        <Box display="flex" flex={1}>
          <Typography variant="body1" fontWeight="bold">
            {props.t(".existingEnrollmentDaysLeft")}
          </Typography>
        </Box>
        <Box display="flex" flex={1} flexDirection="row" alignItems="center">
          <Typography variant="body1" mr={1}>
            {props.enrollment.existingAttendeeSelectionPeriodInDays}
          </Typography>
          <Typography variant="body1">{props.t(".days")}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
        <Box display="flex" flex={1}>
          <Typography variant="body1" fontWeight="bold">
            {props.t(".incomingEnrollmentDaysLeft")}
          </Typography>
        </Box>
        <Box display="flex" flex={1} flexDirection="row" alignItems="center">
          <Typography variant="body1" mr={1}>
            {props.enrollment.incomingAttendeeSelectionPeriodInDays}
          </Typography>
          <Typography variant="body1">{props.t(".days")}</Typography>
        </Box>
      </Box>
    </Paper>

    <Paper padding="16px 16px 24px 16px" mt={4}>
      <Form style={{ width: "100%" }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={4}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" mr={2}>
              <HeadIcon
                name="PeopleOutlined"
                htmlColor={AppColor["Secondary/Hover"]}
              />
            </Box>
            <Typography variant="h6" color={AppColor["Secondary/Hover"]}>
              {props.t(".insuranceDetails")}
            </Typography>
          </Box>
          <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
            {!props.viewOnly && (
              <OutlinedButton variant="outlined" onClick={props.editGrouping}>
                <HeadIcon name="EditOutlined" style={{ marginRight: "10px" }} />
                {props.t(".edit")}
              </OutlinedButton>
            )}
          </Authorize>
        </Box>
        {props.attendeeGroups?.map((attendeeGroup: any, index: any) => (
          <Accordion
            expanded={[attendeeGroup?.title].every((attend: any) =>
              props.expandedList.includes(attend)
            )}
            // expanded={true}
            key={attendeeGroup?.title}
            onChange={props.handleChange(attendeeGroup?.title)}
            style={{
              borderBottom: [attendeeGroup?.title].every((attend: any) =>
                props.expandedList.includes(attend)
              )
                ? ""
                : `1px solid ${AppColor["Background/Dark Grey"]}`,
            }}
          >
            <AccordionSummary
              expandIcon={
                <MaterialIcon
                  name="ExpandMore"
                  htmlColor={AppColor["Text/Dark Grey"]}
                />
              }
            >
              <Typography variant="body1" color={AppColor["Text/Black"]}>
                {attendeeGroup?.title +
                  " (" +
                  props.employeeTableData(attendeeGroup?.title).length +
                  ")"}
              </Typography>
            </AccordionSummary>
            <Box borderTop="0.5px solid #E0E0E0" />
            <AccordionDetails>
              <Box display="flex" flexDirection="column" width="100%">
                <Box display="flex" flex={1} flexDirection="row" mx={4} mt={6}>
                  <Box display="flex" flex={1} flexDirection="column">
                    <Box display="flex" flex={1} flexDirection="row">
                      <Box display="flex" flex={1}>
                        <Typography
                          variant="body1"
                          color={AppColor["Text/Black"]}
                          fontWeight="bold"
                        >
                          {props.t(".insuraceDetail")}
                        </Typography>
                      </Box>
                      <Box display="flex" flex={1}>
                        <Typography
                          variant="body1"
                          flex={1}
                          noWrap
                          width={256}
                          style={
                            props.attendeeGroups[index].file
                              ? {
                                  textDecoration: "underline",
                                  color: AppColor["Other/Info"],
                                }
                              : {}
                          }
                          onClick={() =>
                            props.download(props.attendeeGroups[index].file)
                          }
                        >
                          {props.attendeeGroups[index].file
                            ? props.attendeeGroups[index].file.fileName
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flex={1}
                      style={{ marginBottom: "8px", marginTop: "8px" }}
                      flexDirection="row"
                    >
                      <Box display="flex" flex={1}>
                        <Typography
                          variant="body1"
                          color={AppColor["Text/Black"]}
                          fontWeight="bold"
                        >
                          {props.t(".score")}
                        </Typography>
                      </Box>
                      <Box display="flex" flex={1}>
                        <Typography
                          variant="body1"
                          color={AppColor["Text/Black"]}
                        >
                          {toCurrency(attendeeGroup?.point, {
                            minimumFractionDigits: 0,
                          }) || ""}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" flex={1} flexDirection="row">
                      <Box display="flex" flex={1}>
                        <Typography
                          variant="body1"
                          color={AppColor["Text/Black"]}
                          fontWeight="bold"
                        >
                          {props.t(".type")}
                        </Typography>
                      </Box>
                      <Box display="flex" flex={1}>
                        <Typography
                          variant="body1"
                          color={AppColor["Text/Black"]}
                        >
                          {props.t(
                            "." +
                              attendeeGroup?.masterBenefitInsurances[0]?.type
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" width={125}>
                    <Authorize
                      permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}
                    >
                      {!props.viewOnly &&
                        attendeeGroup?.masterBenefitInsurances?.length > 0 &&
                        attendeeGroup?.masterBenefitInsurances[0]?.type !==
                          "none" && (
                          <OutlinedButton
                            variant="outlined"
                            onClick={() =>
                              props.handleClickPreview(attendeeGroup)
                            }
                          >
                            <HeadIcon
                              name="VisibilityOutlined"
                              style={{ marginRight: "10px" }}
                            />
                            {props.t(".preview")}
                          </OutlinedButton>
                        )}
                    </Authorize>
                  </Box>
                </Box>
                <TableBox mt={6} mb={4}>
                  <Button
                    id="employee"
                    minWidth={103}
                    onClick={() =>
                      props.changeTab(attendeeGroup?.id, "employee", "", "")
                    }
                    variant={
                      props.tab[index].tab === "employee"
                        ? "contained"
                        : "outlined"
                    }
                    mr={4}
                    mb={4}
                  >
                    {props.t(".employees")}
                  </Button>
                  {attendeeGroup?.masterBenefitInsurances?.length > 0 ? (
                    props
                      .getInsuranceTypes(
                        attendeeGroup?.masterBenefitInsurances[0]
                          ?.masterBenefitInsurancePlans
                      )
                      .map((type: any) => (
                        <Button
                          id={type.id}
                          minWidth={103}
                          onClick={() =>
                            props.changeTab(
                              attendeeGroup?.id,
                              type.id,
                              type.symbol,
                              type.name
                            )
                          }
                          variant={
                            props.tab[index].tab === type.id
                              ? "contained"
                              : "outlined"
                          }
                          mr={4}
                          mb={4}
                        >
                          {type.name}
                        </Button>
                      ))
                  ) : (
                    <></>
                  )}
                  {attendeeGroup?.masterBenefitInsurances?.length > 0 ? (
                    attendeeGroup?.masterBenefitInsurances[0]?.masterBenefitInsurancePackages?.map(
                      (masterBenefitInsurancePackage: any) => (
                        <Button
                          minWidth={103}
                          onClick={() =>
                            props.changeTab(
                              attendeeGroup?.id,
                              masterBenefitInsurancePackage.id,
                              "",
                              masterBenefitInsurancePackage
                                .masterInsurancePackage.name
                            )
                          }
                          variant={
                            props.tab[index].tab ===
                            masterBenefitInsurancePackage.id
                              ? "contained"
                              : "outlined"
                          }
                          mr={4}
                          mb={4}
                        >
                          {
                            masterBenefitInsurancePackage.masterInsurancePackage
                              .name
                          }
                        </Button>
                      )
                    )
                  ) : (
                    <></>
                  )}
                  <Box mt={3}>
                    {props.tab[index].tab === "employee" && (
                      <>
                        <Box mb="12px">
                          <Typography
                            variant="h6"
                            color={AppColor["Text/Black"]}
                          >
                            {props.t(".employeeDetail")}
                          </Typography>
                        </Box>
                        <Table
                          className="attendeeTable"
                          columns={props.employeeTableColumns}
                          rows={props.employeeTableData(attendeeGroup?.title)}
                          density="compact"
                          autoHeight
                          disableSelectionOnClick
                        />
                      </>
                    )}
                    {attendeeGroup?.masterBenefitInsurances?.length > 0 ? (
                      attendeeGroup?.masterBenefitInsurances[0]?.type ===
                        "custom" &&
                      props.tab[index].tab !== "employee" && (
                        <>
                          <Box mt="12px" mb="12px">
                            <Typography
                              variant="h6"
                              color={AppColor["Text/Black"]}
                            >
                              {props.t(".customTitle", {
                                nameTitle: props.tab[index].name,
                              })}
                            </Typography>
                          </Box>
                          <Table
                            className="attendeeTable"
                            columns={props.customTableColumns}
                            rows={props.customTableData(
                              attendeeGroup?.masterBenefitInsurances[0]
                                ?.masterBenefitInsurancePlans,
                              props.tab[index].symbol
                            )}
                            density="compact"
                            autoHeight
                            disableSelectionOnClick
                          />
                        </>
                      )
                    ) : (
                      <></>
                    )}
                    {attendeeGroup?.masterBenefitInsurances?.length > 0 ? (
                      attendeeGroup?.masterBenefitInsurances[0]?.type ===
                        "package" &&
                      props.tab[index].tab !== "employee" && (
                        <>
                          <Box
                            mt="12px"
                            mb="12px"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="h6"
                              color={AppColor["Text/Black"]}
                            >
                              {props.t(".packageTitle", {
                                nameTitle: props.tab[index].name,
                              })}
                            </Typography>
                            <Box display="flex" flexDirection="row">
                              {attendeeGroup?.masterBenefitInsurances[0]?.masterBenefitInsurancePackages.filter(
                                (masterBenefitInsurancePackage: any) =>
                                  masterBenefitInsurancePackage.id ===
                                  props.tab[index].tab
                              )[0].isDefault && (
                                <>
                                  <MaterialIcon
                                    name="Check"
                                    style={{
                                      fontSize: "16px",
                                      color: AppColor["Other/Success"],
                                      marginTop: "2px",
                                      marginRight: "8px",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    color={AppColor["Text/Black"]}
                                  >
                                    {props.t(".default")}
                                  </Typography>
                                </>
                              )}
                            </Box>
                          </Box>
                          <Table
                            className="attendeeTable"
                            columns={props.packageTableColumns}
                            rows={props.packageTableData(
                              attendeeGroup?.masterBenefitInsurances[0]
                                ?.masterBenefitInsurancePackages,
                              props.tab[index].tab
                            )}
                            density="compact"
                            autoHeight
                            disableSelectionOnClick
                          />
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </Box>
                </TableBox>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Form>
    </Paper>

    <Paper px={4} py={4} mt={4}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        px={3}
        py={3}
        border={AppColor["Other Light/Warning"]}
        borderRadius={4}
        bgcolor={AppColor["Other Light/Warning"]}
      >
        <MaterialIcon name="ReportProblemOutlined" />
        <Typography variant="body1" ml={2} color={AppColor["Text/Primary"]}>
          {props.t(".warningMessage")}
        </Typography>
      </Box>
      <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
        {!props.viewOnly && (
          <Button style={{ marginTop: "16px" }} onClick={props.handlePublish}>
            {props.t(".published")}
          </Button>
        )}
      </Authorize>
    </Paper>
    <Dialog open={props.isShowLoading}>
      <DialogTitle></DialogTitle>
      <DialogContent
        style={{
          objectFit: "contain",
          padding: "59px 24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Loader />
        <Box mt="24px">
          <Typography>{props.t(".publishing")}</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  </>
);

const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      enrollmentInValidating(id: $id) {
        id
        year
        state
        subState
        startDate
        endDate
        existingAttendeeSelectionPeriodInDays
        incomingAttendeeSelectionPeriodInDays
        attendeeGroups {
          id
          titleTh
          titleEn
          point
          masterBenefitInsurances {
            id
            type
            masterBenefitInsurancePlans {
              id
              isDefault
              insuranceId
              masterInsurancePlan {
                id
                nameTh
                nameEn
                remarkTh
                remarkEn
                premium
                description
                insuranceTypeId
                insuranceType {
                  id
                  symbol
                  nameTh
                  nameEn
                }
              }
            }
            masterBenefitInsurancePackages {
              id
              isDefault
              insurancePackageId
              masterInsurancePackage {
                id
                nameTh
                nameEn
                remarkTh
                remarkEn
                insurancePackagesInsurancePlans {
                  id
                  insurance {
                    id
                    nameTh
                    nameEn
                    remarkTh
                    remarkEn
                    premium
                    description
                  }
                }
              }
            }
          }
          file {
            url
            fileName
          }
        }
      }
    }
  `,
  FETCH_EMPLOYEES: gql`
    query FETCH_EMPLOYEES($enrollmentId: String) {
      getEmployees(input: { enrollmentId: $enrollmentId }) {
        id
        code
        groupTh
        groupEn
        positionTh
        positionEn
        departmentTh
        departmentEn
        nameTh
        nameEn
        probationEndDate
      }
    }
  `,
  CHECK_MASTER_INSURANCE_STATE: gql`
    mutation CHECK_MASTER_INSURANCE_STATE($id: String!) {
      checkMasterInsuranceState(id: $id) {
        isPublished
      }
    }
  `,
  PUBLISH_ENROLLMENT: gql`
    mutation PUBLISH_ENROLLMENT($id: String!) {
      publishEnrollment(input: { id: $id }) {
        id
        state
        subState
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => ({}),
  }),
  withTranslation({
    prefix: "pages.main.enrollment.validate_and_published.index",
  }),
  withHooks((props: any, hooks: any) => {
    const {
      useParams,
      useMemo,
      useCallback,
      useState,
      useQuery,
      useMutation,
      useDataTranslation,
    } = hooks;
    const { t, currentUser } = props;

    const [expandedList, setExpandedList] = useState([]);
    const [isShowLoading, setIsShowLoading] = useState(false);
    const [tab, setTab] = useState([]);
    const { id } = useParams();

    const { data: enrollmentResponse, loading, refetch } = useQuery(
      API.GET_ENROLLMENT,
      {
        variables: { id },
        fetchPolicy: "network-only",
      }
    );
    const enrollmentTranslated = useDataTranslation(enrollmentResponse);
    const { data: employeeResponse } = useQuery(API.FETCH_EMPLOYEES, {
      variables: { enrollmentId: id },
      fetchPolicy: "network-only",
    });
    const employeeResponseTranslated = useDataTranslation(employeeResponse);

    const { data: insuranceTypesResponse } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );
    const insuranceTypesTranslated = useDataTranslation(
      insuranceTypesResponse?.insuranceTypes
    );
    const insuranceTypes = useMemo(() => {
      if (insuranceTypesTranslated) {
        return insuranceTypesTranslated;
      }
      return [];
    }, [insuranceTypesTranslated]);

    const [publishEnrollment] = useMutation(API.PUBLISH_ENROLLMENT, {
      skipSetError: true,
      onCompleted: () => {
        setIsShowLoading(false);
        paths.enrollmentPath().push();
        notifySuccess(t(".success"));
      },
      onError: (errorResponse: any) => {
        const errors = get(errorResponse, "networkError.result.errors");
        if (errors[0].message === "updateUnsuccessfully") {
          publishedAlert();
        }
        const isSyncFileError = errors[0].message.split("Error: ");
        if (isSyncFileError[1]?.includes("Sync File Error")) {
          Modal.alert({
            title: t(".syncEmployeeErrorOnPublishedTitle"),
            children: t(".syncEmployeeErrorOnPublished"),
            okButtonLabel: t("client.error.accept"),
            onOk: async ({ close }: any) => {
              close();
              window.location.reload();
            },
            disableBackdropClick: true,
          });
        } else {
          Notification.error(errors[0]?.extensions?.originalError[0]?.message);
        }
        setIsShowLoading(false);
      },
    });
    const [validateInsuranceState] = useMutation(
      API.CHECK_MASTER_INSURANCE_STATE
    );

    const attendeeGroups = useMemo(() => {
      const tab = enrollmentTranslated?.enrollmentInValidating?.attendeeGroups.map(
        (attendeeGroup: any) => {
          return {
            id: attendeeGroup.id,
            title: attendeeGroup.title,
            tab: "employee",
            symbol: "",
            name: "",
          };
        }
      );
      setTab(tab);
      return enrollmentTranslated?.enrollmentInValidating?.attendeeGroups;
    }, [enrollmentTranslated, setTab]);

    const download = useCallback(async (file: any) => {
      if (file) {
        let blob = await fetch(file.url)
          .then((r) => r.blob())
          .then(
            (blobFile) =>
              new File([blobFile], file.fileName, { type: blobFile.type })
          );
        const url = URL.createObjectURL(blob);
        forceDownload(url, file.fileName);
      }
    }, []);

    const handlePublish = useCallback(async () => {
      let validateParams = true;
      let groupTitleValidateFail = "";
      for (const attendeeGroup of attendeeGroups) {
        if (
          attendeeGroup["masterBenefitInsurances"][0].type !== "none" &&
          (!attendeeGroup["point"] ||
            attendeeGroup["masterBenefitInsurances"].length === 0)
        ) {
          groupTitleValidateFail += attendeeGroup["title"] + " ";
          validateParams = false;
        }
      }
      if (!validateParams) {
        Modal.alert({
          title: t(".errorTitle"),
          children: t(".errorValidateText", { title: groupTitleValidateFail }),
          okButtonLabel: t(".submit"),
          onOk: async ({ close }: any) => {
            close();
          },
          okButtonColor: AppColor["Primary/Primary Text"],
          disableBackdropClick: true,
        });
        return;
      }
      const isPublish = await validateInsuranceState({ variables: { id } });
      if (isPublish.data.checkMasterInsuranceState.isPublished) {
        Modal.open({
          title: t(".publishEnrollment"),
          children: (
            <>
              <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                {t(".publishModalText")}
              </Typography>
              <Box mb={6}>
                <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                  {t(".publishModalText2")}
                </Typography>
              </Box>
            </>
          ),
          cancelButtonLabel: t(".cancel"),
          okButtonLabel: t(".published"),
          onOk: async ({ close }: any) => {
            try {
              close();
              setIsShowLoading(true);
              await publishEnrollment({
                variables: { id },
              });
            } catch (error: any) {
              setIsShowLoading(false);
              close();
              Modal.alert({
                title: t(".errorTitle"),
                children: error.message,
                okButtonLabel: t(".submit"),
                onOk: async ({ close }: any) => {
                  close();
                },
                okButtonColor: AppColor["Primary/Primary"],
                disableBackdropClick: true,
              });
            }
          },
          okButtonColor: AppColor["Other/Danger"],
        });
      } else {
        Modal.alert({
          title: t(".errorTitle"),
          children: (
            <>
              <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                {t(".errorModalText")}
              </Typography>
              <Box mb={6}>
                <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                  {t(".errorModalText2")}
                </Typography>
              </Box>
            </>
          ),
          okButtonLabel: t(".submit"),
          okButtonColor: AppColor["Other/Info"],
          onOk: async ({ close }: any) => {
            close();
          },
          disableBackdropClick: true,
        });
      }
    }, [validateInsuranceState, id, t, publishEnrollment, attendeeGroups]);

    const handleChange = useCallback(
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedList(
          isExpanded
            ? uniq([...expandedList, panel])
            : expandedList.filter((expand: any) => expand !== panel)
        );
      },
      [setExpandedList, expandedList]
    );

    const mapType = useCallback((type: string) => {
      console.log(type);

      switch (type) {
        case "custom":
          return "ให้เลือกเอง";
        case "package":
          return "ให้เลือกจาก Package ประกัน";
        case "none":
          return "ไม่เปิดให้เลือก";
        default:
          return "";
      }
    }, []);

    const getInsuranceTypes = useCallback(
      (masterBenefitInsurancePlans: [any]) => {
        let type = masterBenefitInsurancePlans.map(
          ({ masterInsurancePlan }: any) => {
            const { insuranceType } = masterInsurancePlan;
            return {
              id: insuranceType.id,
              symbol: insuranceType.symbol,
              name: insuranceType.name,
            };
          }
        );
        const uniqType = uniqBy(type, "symbol");
        return uniqType;
      },
      []
    );

    const changeTab = useCallback(
      (id: any, tabId: any, symbol: any, name: any) => {
        const change = tab.map((t: any) => {
          if (id === t.id) {
            return { ...t, tab: tabId, symbol, name };
          }
          return t;
        });
        setTab(change);
      },
      [tab, setTab]
    );

    const employees = useMemo(() => {
      return employeeResponseTranslated?.getEmployees || [];
    }, [employeeResponseTranslated]);

    const employeeTableData: any = useCallback(
      (title: any) => {
        const response = employees.filter(
          (employee: any) => employee.group === title
        );
        return response;
      },
      [employees]
    );

    const customTableData = useCallback(
      (masterBenefitInsurancePlans: [any], symbol: any) => {
        const filterSymbols = masterBenefitInsurancePlans.filter(
          (masterBenefitInsurancePlans) =>
            masterBenefitInsurancePlans.masterInsurancePlan.insuranceType
              .symbol === symbol
        );
        const response = filterSymbols.map((filterSymbol) => {
          return {
            ...filterSymbol.masterInsurancePlan,
            isDefault: filterSymbol.isDefault,
          };
        });
        return response;
      },
      []
    );

    const packageTableData = useCallback(
      (masterBenefitInsurancePackages: [any], id: any) => {
        const filterId = masterBenefitInsurancePackages.filter(
          (masterBenefitInsurancePackage) =>
            masterBenefitInsurancePackage.id === id
        );
        const response = filterId[0].masterInsurancePackage.insurancePackagesInsurancePlans.map(
          (insurancePackagesInsurancePlan: any) => {
            return { ...insurancePackagesInsurancePlan.insurance };
          }
        );
        return response;
      },
      []
    );

    const editPeriodSetting = useCallback(() => {
      handleNavigateEnrollmentStep(id, EnumEnrollmentSubState.period_editing);
    }, [id]);

    const editGrouping = useCallback(() => {
      handleNavigateEnrollmentStep(
        id,
        EnumEnrollmentSubState.attendee_groups_editing
      );
    }, [id]);

    const enrollment = useMemo(() => {
      return {
        year: enrollmentTranslated?.enrollmentInValidating?.year,
        startDate: enrollmentTranslated?.enrollmentInValidating?.startDate,
        endDate: enrollmentTranslated?.enrollmentInValidating?.endDate,
        existingAttendeeSelectionPeriodInDays:
          enrollmentTranslated?.enrollmentInValidating
            ?.existingAttendeeSelectionPeriodInDays,
        incomingAttendeeSelectionPeriodInDays:
          enrollmentTranslated?.enrollmentInValidating
            ?.incomingAttendeeSelectionPeriodInDays,
      };
    }, [enrollmentTranslated]);

    const employeeTableColumns = useMemo(
      () => [
        {
          width: 120,
          field: "code",
          headerName: t(".code") || "",
        },
        {
          width: 200,
          field: "name",
          headerName: t(".name") || "",
        },
        {
          width: 180,
          field: "department",
          headerName: t(".department") || "",
        },
        {
          width: 180,
          field: "position",
          headerName: t(".position") || "",
        },
      ],
      [t]
    );

    const customTableColumns = useMemo(
      () => [
        {
          width: 200,
          field: "name",
          headerName: t(".insuranceName") || "",
        },
        {
          width: 300,
          field: "name",
          headerName: t(".remark") || "",
        },
        {
          width: 90,
          field: "isDefault",
          headerName: t(".default") || "",
          type: "isDefault",
        },
      ],
      [t]
    );

    const packageTableColumns = useMemo(
      () => [
        {
          width: 200,
          field: "name",
          headerName: t(".insuranceName") || "",
        },
        {
          width: 400,
          field: "remark",
          headerName: t(".remark") || "",
        },
      ],
      [t]
    );

    const isPending = useMemo(
      () =>
        enrollmentTranslated?.enrollmentInValidating?.state ===
        EnumEnrollmentState.pending,
      [enrollmentTranslated]
    );

    const viewOnly = useMemo(() => {
      let valid = true;
      valid =
        valid &&
        isEqual(
          enrollmentTranslated?.enrollmentInValidating?.state,
          EnumEnrollmentState.published
        );
      valid =
        valid ||
        isEqual(
          enrollmentTranslated?.enrollmentInValidating?.state,
          EnumEnrollmentState.active
        );
      valid =
        valid ||
        isEqual(
          enrollmentTranslated?.enrollmentInValidating?.state,
          EnumEnrollmentState.closed
        );
      return valid;
    }, [enrollmentTranslated]);

    const handleClickPreview = useCallback(
      async (attendeeGroup: any) => {
        const { year, startDate, endDate } = enrollment;
        const storageClient = new CrossStorageClient(`${env.HOST}`, {});
        const crossStorageData = {
          ...attendeeGroup,
          year,
          startDate,
          endDate,
          insuranceType: insuranceTypes,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        };
        storageClient.onConnect().then(() => {
          storageClient.set(
            "CROSS_STORAGE_DATA",
            JSON.stringify(crossStorageData)
          );
        });
        window.open(`${appEnv.HOST}/preview/${attendeeGroup.id}`);
      },
      [enrollment, currentUser, insuranceTypes]
    );

    return {
      download,
      attendeeGroups,
      handleChange,
      expandedList,
      isPending,
      enrollment,
      mapType,
      employeeTableColumns,
      customTableColumns,
      employeeTableData,
      getInsuranceTypes,
      changeTab,
      tab,
      customTableData,
      packageTableData,
      packageTableColumns,
      editPeriodSetting,
      editGrouping,
      handlePublish,
      viewOnly,
      handleClickPreview,
      isShowLoading,
    };
  })
);

export default enhancer(PublishEnrollment);
