import { findKey } from "lodash";
import { isClient } from "common/helper";
import { cleanEnv, str } from "envalid";

export const envConfig = cleanEnv(process.env, {
  REACT_APP_ENV: str(),
  REACT_APP_VERSION: str({ default: "0.0.0.0" }),
  REACT_APP_DEFAULT_LANG: str({ default: "th" }),
  NODE_ENV: str({ default: "development" }),
});

const config = {
  production: {
    HOST: "https://flex-backoffice.axa.zennite.co",
    API_ENDPOINT: "https://flex-api.axa.zennite.co/graphql",
    API: "https://flex-api.axa.zennite.co",
    BACKOFFICE_ENDPOINT: "https://flex-backoffice.axa.zennite.co",
    APP_ENDPOINT: "https://flex.axa.zennite.co",
    MARKETPLACE_ENDPOINT: "https://marketplace.api.zennite.co",
  },
  uat: {
    HOST: "https://flex.backoffice.axa-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.axa-uat.zennite.co/graphql",
    API: "https://flex-api.axa-uat.zennite.co",
    BACKOFFICE_ENDPOINT: "https://flex.backoffice.axa-uat.zennite.co",
    APP_ENDPOINT: "https://flex.axa-uat.zennite.co",
    MARKETPLACE_ENDPOINT: "https://marketplace.api.uat.zennite.co",
  },
  staging: {
    HOST: "https://flex.backoffice.axa-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.axa-uat.zennite.co/graphql",
    API: "https://flex-api.axa-uat.zennite.co",
    BACKOFFICE_ENDPOINT: "https://flex.backoffice.axa-uat.zennite.co",
    APP_ENDPOINT: "https://flex.axa-uat.zennite.co",
    MARKETPLACE_ENDPOINT: "https://marketplace.api.uat.zennite.co",
  },
  development: {
    HOST: "http://localhost:21702",
    API_ENDPOINT: "http://localhost:22901/graphql",
    API: "http://localhost:22901",
    BACKOFFICE_ENDPOINT: "http://localhost:21702",
    APP_ENDPOINT: "http://localhost:21703",
    MARKETPLACE_ENDPOINT: "https://marketplace.api.uat.zennite.co",
  },
};

export const getEnvByHost = () =>
  isClient ? findKey(config, { HOST: window.location.origin }) : null;

export const nodeEnv = getEnvByHost() || "development";

console.log("HOST : ", getEnvByHost());

const appEnvConfig = {
  production: {
    HOST: "https://flex.axa.zennite.co",
    API_ENDPOINT: "https://flex-api.axa.zennite.co/graphql",
    API: "https://flex-api.axa.zennite.co",
  },
  uat: {
    HOST: "https://flex.axa-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.axa-uat.zennite.co/graphql",
    API: "https://flex-api.axa-uat.zennite.co",
  },
  staging: {
    HOST: "https://flex.axa-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.axa-uat.zennite.co/graphql",
    API: "https://flex-api.axa-uat.zennite.co",
  },
  e2eStaging: {
    HOST: "https://flex.axa-uat.zennite.co",
    API_ENDPOINT: "https://flex-api.axa-uat.zennite.co/graphql",
    API: "https://flex-api.axa-uat.zennite.co",
  },
  development: {
    HOST: "http://localhost:21703",
    API_ENDPOINT: "http://localhost:22901/graphql",
    API: "http://localhost:22901",
  },
};

const MOCK_MSAL_CONFIG = {
  production: {
    REACT_APP_MSAL_CLIENT_ID: "aa3d3245-7975-475f-a0d2-f4728d3f9294",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI: "https://mybenefit-backoffice-flexben.scg.com",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI:
      "https://mybenefit-backoffice-flexben.scg.com",
  },
  uat: {
    REACT_APP_MSAL_CLIENT_ID: "aa3d3245-7975-475f-a0d2-f4728d3f9294",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI:
      "https://mybenefit-backoffice-flexben-uat.scg.com",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI:
      "https://mybenefit-backoffice-flexben-uat.scg.com",
  },
  staging: {
    REACT_APP_MSAL_CLIENT_ID: "96283f69-2148-4bf1-a97a-4478c83a8e41",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI: "https://dev.backoffice.my-benefit.ldrive.io",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI:
      "https://dev.backoffice.my-benefit.ldrive.io",
  },
  development: {
    REACT_APP_MSAL_CLIENT_ID: "96283f69-2148-4bf1-a97a-4478c83a8e41",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI: "http://localhost:21702",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "http://localhost:21702",
  },
};

export const appEnv = appEnvConfig[nodeEnv];

const env = {
  ...config[nodeEnv],
  ...MOCK_MSAL_CONFIG[nodeEnv],
};

console.log("ENV : ", env);
export default env;
